import { Module } from "vuex";
import { RootStateType } from "../types";
import { LocationStateInterface, LocationStateModel } from "./types";

import actions from "./actions";
import mutations from "./mutations";

const location: Module<LocationStateInterface, RootStateType> = {
    namespaced: true,
    state: new LocationStateModel,
    actions,
    mutations
}


export default location
