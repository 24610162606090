import { MutationTree } from "vuex";
import { CheckoutPaymentInterface, PaymentChannelModel, ProductStateModel, ProductStateType, ProviderInterface, ProviderModel } from "./types";

const mutations: MutationTree<ProductStateModel> = {
    "set-providers": (state, payload: ProviderInterface[]) => {
        state.Providers = []
        payload.forEach((v)=>state.Providers.push((new ProviderModel).SetRawAttributes(v)))
    },
    "set-payment-option": (state, options: CheckoutPaymentInterface[]) => {
        state.payment_optons = options.map((item) => (new PaymentChannelModel).SetRawAttributes(item))
    }
}

export default mutations
