import { BaseModel } from "@/constant/BaseModel";
import { UserModel } from "@/store/auth/types";
import { ProvinceInterface } from "@/store/location/types";
import { BSelectOption } from "@/store/types";
import { CompareContentInterface } from "../mobil/types";
import { CheckoutPaymentModel, ConfirmationUserModel, ProductDataInterface, ProductDataModel, ProductInterface, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, ProductTestimonyModel } from "../types";

export interface ProductKesehatanStateInterface {
    onLoad: boolean;
    title: string;
    content: string;
    banner: string
    states: ProvinceInterface[];
    listCompare: CompareContentInterface[]
}

export class ProductKesehatanStateModel extends BaseModel implements ProductKesehatanStateInterface {
    onLoad: boolean = false;
    title: string = "";
    content: string = "";
    banner: string = ""
    states: ProvinceInterface[] = [];
    form: FormCompareKesehatanInterface = new FormCompareKesehatanModel
    data: ProductDataInterface = new ProductDataModel
    summary: ProductSummaryInterface = new ProductSummaryModel
    /**
     * ### NOTE:
     * >Participant status set to 1 = family
     * >Many product does not have health_fare for participant status 2 = individual
     * >1 person family is assumed to be roughly equivalent as an individual
    */
    participant_type: BSelectOption[] = [
        // { text: "family", value: 1 },
        { text: "individu", value: 2 }, // [NOTE]: the val will change to 1 when request fired. check BE!
        // { text: "couple", value: 3 }
    ];
    testimony: ProductTestimonyInterface = new ProductTestimonyModel;
    confirmation: PaymentProductKesehatanConfirmationInterface = new PaymentProductKesehatanConfirmationModel;
    checkout: CheckoutPaymentModel = new CheckoutPaymentModel
    listCompare: CompareContentInterface[] = []
}

export interface FormCompareKesehatanInterface {
    /**
     * 1. family
     * 2. individu
     * 3. couple
    */
    participant_status: number;
    gender: number;
    bod_individu: string | null;
    arrival_date_formated: string | null;

    /**
     * 1 = no child
     * 2 = with child
    */
    child_status: number | null;
    bod_husband: string | null;
    dobformated: string | null;
    bod_wife: string | null;
    bod_child: string[] | null;
    dobformatedchild: string[] | null;
}

export class FormCompareKesehatanModel extends BaseModel implements FormCompareKesehatanInterface {
    participant_status: number = 0;
    gender: number = 0;
    bod_individu: string | null = null;
    arrival_date_formated: string | null = null;
    child_status: number | null = null;
    bod_husband: string | null = null;
    dobformated: string | null = null;
    bod_wife: string | null = null;
    bod_child: string[] | null = null;
    dobformatedchild: string[] | null = null;
    sort: string = 'a-z'
}

export interface PaymentProductKesehatanConfirmationInterface {
    product: ProductInterface
    user: ConfirmationUserModel;
}

export class PaymentProductKesehatanConfirmationModel extends BaseModel implements PaymentProductKesehatanConfirmationInterface {
    product: ProductModel = new ProductModel;
    user: ConfirmationUserModel = new ConfirmationUserModel
}
