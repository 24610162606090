import { MutationTree } from "vuex";
import { PartnerProviderModel, PartnerStateInterface } from "./types";

const mutations: MutationTree<PartnerStateInterface> = {
    "set-partner-providers": (state, payload: PartnerStateInterface) => {
        state.main_banner = payload.main_banner
        state.page_title = payload.page_title
        state.site_lang = payload.site_lang
        state.providers = []
        payload.providers.forEach((v)=>state.providers.push((new PartnerProviderModel).SetRawAttributes(v)))
    }
}

export default mutations
