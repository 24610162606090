import { BaseModel } from "@/constant/BaseModel";
import { AdditionalCoverModel } from "./motor/types";

export interface ProductStateType {
    Providers: ProviderInterface[],
    payment_optons: PaymentChannelInterface[]
}

export class ProductStateModel extends BaseModel implements ProductStateType {
    Providers: ProviderModel[] = [];
    payment_optons: PaymentChannelInterface[] = [];
}

export interface ProviderInterface {
    provider_id: string;
    provider_name: string;
    alias_name: string;
    address: string;
    email: string;
    phone: string;
    fax: string;
    logo: string;
    detail_ind: string;
    detail_eng: string;
    website: string;
    subdomain: string;
    show_provider: string;
    seo_link: string;
    seo_key: string | null;
    seo_desc: string | null;
    seo_title: string | null;
    status: string;
    is_deleted: string;
    created_by: string;
    created_date: string;
    updated_by: string | null;
    updated_date: string | null;
    old_provider_id: string;
}

export class ProviderModel extends BaseModel implements ProviderInterface {
    provider_id: string = "";
    provider_name: string = "";
    alias_name: string = "";
    address: string = "";
    email: string = "";
    phone: string = "";
    fax: string = "";
    logo: string = "";
    detail_ind: string = "";
    detail_eng: string = "";
    website: string = "";
    subdomain: string = "";
    show_provider: string = "";
    seo_link: string = "";
    seo_key: string | null = null;
    seo_desc: string | null = null;
    seo_title: string | null = null;
    status: string = "";
    is_deleted: string = "";
    created_by: string = "";
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string | null = null;
    old_provider_id: string = "";
}


export interface ProductInterface {
    product_id: string;
    provider_id: string;
    provider_name: string;
    logo: string;
    insurance_type_id: string;
    basic_commision: string;
    additional_commision: string;
    tax_rate: string;
    handling_fee: string;
    discount_rate: string;
    col1: string;
    col2: string;
    col3: string;
    coverage_type: string;
    admin_fee: string;
    year: string;
    area: string;
    sum_insured: string;
    rate_basic: string;
    gross_premium: string;
    discount_premium: string;
    gross_total_temp: string;
    discounted_total_temp: string;
    product_name: string;
    workshop: {
        local: number;
        others: number;
    }|number,
    additional: ProductAdditionalInterface[],
    feature: ProductFeatureInterface[];
    additional_premi: number;
    encrypted: string;
    promo_code: string;
    loading_rate_percent: string;
    loading_rate_amount: string;
}

export class ProductModel extends BaseModel implements ProductInterface {
    constructor(attributes?: any) {
        super();
        if (attributes) this.SetRawAttributes(attributes);
    }

    product_id: string = "";
    provider_id: string = "";
    provider_name: string = "";
    logo: string = "";
    insurance_type_id: string = "";
    basic_commision: string = "";
    additional_commision: string = "";
    tax_rate: string = "";
    handling_fee: string = "";
    discount_rate: string = "";
    col1: string = "";
    col2: string = "";
    col3: string = "";
    coverage_type: string = "";
    admin_fee: string = "";
    year: string = "";
    area: string = "";
    sum_insured: string = "";
    rate_basic: string = "";
    gross_premium: string = "";
    discount_premium: string = "";
    gross_total_temp: string = "";
    discounted_total_temp: string = "";
    product_name: string = "";
    workshop = {
        local: 0,
        others: 0,
    };
    additional: ProductAdditionalInterface[] = [];
    feature: ProductFeatureInterface[] = [];
    additional_premi: number = 0;
    encrypted: string = "";
    promo_code: string = "";
    loading_rate_percent: string = "";
    loading_rate_amount: string = "";

    // From Global Product
    compare: string = "";
    quotation_id: string = "";

    // From Asuransi Property
    basic_premi?: string;
    home_package_id?: string;
    home_package_name?: string;
    is_banjir?: string;
    is_gempa?: string;
    is_huru_hara?: string;
    rate?: string;
    type_rate?: string;
    zone?: string;
    zone_rate_5?: string;

    // From Asuransi Kesehatan
    participant_status?: string;
    individu_age?: number;
    gender?: string;
    child_status?: string;
    child_amount?: number;
    husband_age?: number;
    wife_age?: number;
    health_fare_id?: string;
    health_package_id?: string;
    health_package_name?: string;
    min_age?: string;
    max_age?: string;
    with_child?: string;
    max_child?: string;
    is_child_limited?: string;
    surcharge?: string;
    premi?: string;
    is_direct_payment?: string;
    periode?: string;
    additional_child?: number;
    add_commision_rate?: string;
    premi_add_child?: number;
    total_premi?: number;
    total_premium?: string
    is_cashless?: string;
    is_rawat_jalan?: string;
    is_rawat_jalan_limited?: string;
    benefit_rawat_jalan?: string;
    is_rawat_inap?: string;
    is_rawat_inap_limited?: string;
    benefit_rawat_inap?: string;
    desc_rawat_inap?: string;
    is_rawat_bedah?: string;
    is_rawat_bedah_limited?: string;
    benefit_rawat_bedah?: string;
    logo_url?: string;
    is_renewal?: string;
    renewal?: string;
    is_provider_available?: string;
    provider?: string;
    system?: string;
    LblCompany?: string;
    LblPremium?: string;
    LblDescPremium?: string;
    LblOutpatient?: string;
    LblDescOutpatient?: string;
    LblInpatient?: string;
    LblDescInpatient?: string;
    LblSurgery?: string;
    LblDescSurgery?: string;
    LblPartnership?: string;

    //From KecelakaanDiri
    col4?: string;
    pa_matrix_id?: string;
    package_id?: string;
    periode_days?: string;
    periode_desc?: string;
    price?: string;
    total_price?: string;

    // from properti(rumah)
    zone_rate_4?: string;

    /**
     * available when confirm product response
    */
    additional_discount_code?: string;
    additional_discount_rate?: string;
    additional_discount_amount?: number;
}

export interface ProductAdditionalInterface {
    vehicle_additional_category_id: string;
    additional_category_name_ind: string;
    alias: string;
    vehicle_additional_id: string;
    premi: number;
    limit_coverage: string;
    passenger: string;
    vehicle_additional_matrix_id: string;
    type_limit: string;
    limit_atas: string;
    limit_bawah: string;
    type_rate: string;
    rate: string;
    limit_person: string;
    vehicle_additional_foreign_to_product_id: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export class ProductAdditionalModel extends BaseModel implements ProductAdditionalInterface {
    vehicle_additional_category_id: string = "";
    additional_category_name_ind: string = "";
    alias: string = "";
    vehicle_additional_id: string = "";
    premi: number = NaN;
    limit_coverage: string = "";
    passenger: string = "";
    vehicle_additional_matrix_id: string = "";
    type_limit: string = "";
    limit_atas: string = "";
    limit_bawah: string = "";
    type_rate: string = "";
    rate: string = "";
    limit_person: string = "";
    vehicle_additional_foreign_to_product_id: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
}

export interface ProductSummaryInterface {
    feature: {
        total: number;
        listdata: any[];
    },
    clause: {
        total: number,
        listdata: ClauseDataSummaryInterface[];
    };
    deductible: {
        total: number,
        listdata: any[];
    };
    benefit?: {
        total: number,
        listdata: BenefitInterModel[];
    };

    site_lang: string;
    product: ProductInterface;
    provider_id: string;
    action_form: string;
}

export class ProductSummaryModel extends BaseModel implements ProductSummaryInterface {
    feature: { total: number; listdata: any[]; } = {
        total: 0,
        listdata: []
    };

    clause: { total: number; listdata: ClauseDataSummaryInterface[]; } = {
        total: 0,
        listdata: []
    };

    deductible: { total: number; listdata: ClauseDataSummaryInterface[]; } = {
        total: 0,
        listdata: []
    };

    benefit?: { total: number; listdata: BenefitInterModel[]; } = {
        total: 0,
        listdata: []
    };

    site_lang: string = "";
    product: ProductModel = new ProductModel;
    provider_id: string = "";
    action_form: string = "";
    additionals?: AdditionalCoverModel[]
}

export interface BenefitInterface {
    pa_detail_id: string;
    pa_matrix_id: string;
    pa_desc_ind: string;
    pa_desc_eng: string;
    value: string;
    is_parent: string;
    parent_id: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
    dtm: string;
}

export class BenefitInterModel extends BaseModel implements BenefitInterface {
    pa_detail_id: string = "";
    pa_matrix_id: string = "";
    pa_desc_ind: string = "";
    pa_desc_eng: string = "";
    value: string = "";
    is_parent: string = "";
    parent_id: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
    dtm: string = "";
}

export interface ClauseDataSummaryInterface {
    product_content_id: string;
    product_id: string;
    package_id: string;
    category: string;
    detail_ind: string;
    detail_eng: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string | null;
    updated_by: string | null;
    updated_date: string | null;
    dtm: string | null;
}

export class ClauseDataSummaryModel extends BaseModel implements ClauseDataSummaryInterface {
    product_content_id: string = "";
    product_id: string = "";
    package_id: string = "";
    category: string = "";
    detail_ind: string = "";
    detail_eng: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string | null = null;
    updated_by: string | null = null;
    updated_date: string | null = null;
    dtm: string | null = null;
}

export interface ProductBrandInterface {
    brand_id: string;
    brand_name: string;
    has_passenger_car: string;
    has_passenger_non_car: string;
    has_motorcyle: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export class ProductBrandModel extends BaseModel implements ProductBrandInterface {
    brand_id: string = "";
    brand_name: string = "";
    has_passenger_car: string = "";
    has_passenger_non_car: string = "";
    has_motorcyle: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
}

export interface ProductTestimonyInterface {
    avg_rating: number;
    sum_rating: number;
    rating: RatingInterface[];
    list_testimonial: TestimonyInterface[];
}

export class ProductTestimonyModel extends BaseModel implements ProductTestimonyInterface {
    avg_rating: number = 0;
    sum_rating: number = 0;
    rating: RatingInterface[] = [];
    list_testimonial: TestimonyModel[] = [];
}

export interface RatingInterface {
    rating: number;
    data: RatingDetailInterface;
}

export class RatingModel extends BaseModel implements RatingInterface {
    rating: number = 0;
    data: RatingDetailInterface = new RatingDetailModel;
}

export interface RatingDetailInterface {
    total_rating: number;
    data_percentage: number;
}

export class RatingDetailModel extends BaseModel implements RatingDetailInterface {
    total_rating: number = 0;
    data_percentage: number = 0;
}

export interface TestimonyInterface {
    created_dateFormated: string;
    testimonial_id: string;
    trx_id: string;
    provider_id: string | null;
    customer_name: string;
    testimonial_description: string;
    rating: string;
    customer_email: string;
    CreatedDate: string;
    is_product: string;
    is_active: string;
    is_reviewed: string;
    is_send_reviewed: string;
    last_send_request_reviewed: string;
    total_send_request_reviewed: string;
    sticky: string | null;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string | null;
}

export class TestimonyModel extends BaseModel implements TestimonyInterface {
    created_dateFormated: string = "";
    testimonial_id: string = "";
    trx_id: string = "";
    provider_id: string | null = null;
    customer_name: string = "";
    testimonial_description: string = "";
    rating: string = "";
    customer_email: string = "";
    CreatedDate: string = "";
    is_product: string = "";
    is_active: string = "";
    is_reviewed: string = "";
    is_send_reviewed: string = "";
    last_send_request_reviewed: string = "";
    total_send_request_reviewed: string = "";
    sticky: string | null = null;
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string | null = null;
}

export interface PlateInterface {
    plate_number_id: string;
    plate_number_code: string;
    plate_number_city: string;
    area_id: string;
    state_id: string;
    status: string;
    is_deleted: string;
    created_by: string | string | null;
    created_date: string;
    updated_by: string | string | null;
    updated_date: string;
}

export class PlateModel extends BaseModel implements PlateInterface {
    plate_number_id: string = "";
    plate_number_code: string = "";
    plate_number_city: string = "";
    area_id: string = "";
    state_id: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
}


export interface ProductDataInterface {
    listdata: ProductModel[];
    total: number;
}

export class ProductDataModel extends BaseModel implements ProductDataInterface {
    listdata: ProductModel[] = [];
    data?: ProductModel[];
    total: number = 0;
}


export interface ProductFeatureInterface {
    product_content_id: string;
    product_id: string;
    package_id: string;
    category: string;
    detail_ind: string;
    detail_eng: string;
    status: string;
    is_deleted: string;
    created_by: string;
    created_date: string;
    updated_by: string | null;
    updated_date: string | null;
    dtm: string;
}

export class ProductFeatureModel extends BaseModel implements ProductFeatureInterface {
    product_content_id: string = "";
    product_id: string = "";
    package_id: string = "";
    category: string = "";
    detail_ind: string = "";
    detail_eng: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string = "";
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string | null = null;
    dtm: string = "";
}


export interface ConfirmationUserInterface {
    user_id: string;
    first_name: string;
    last_name: string;
    idcard: string;
    gender: string;
    born_date: string;
    occupation: string;
    email: string;
    phone: string;
    address: string;
    city_id: string;
    state_id: string;
    country_id: string;
    zipcode: string;
    shipping_reciever: string;
    shipping_phone: string;
    shipping_address: string;
    status: number;
    is_deleted: number;
    created_date: string;
    updated_date: string;
}

export class ConfirmationUserModel extends BaseModel implements ConfirmationUserInterface {
    user_id: string = "";
    first_name: string = "";
    last_name: string = "";
    idcard: string = "";
    gender: string = "";
    born_date: string = "";
    occupation: string = "";
    email: string = "";
    phone: string = "";
    address: string = "";
    city_id: string = "";
    state_id: string = "";
    country_id: string = "";
    zipcode: string = "";
    shipping_reciever: string = "";
    shipping_phone: string = "";
    shipping_address: string = "";
    status: number = 0;
    is_deleted: number = 0;
    created_date: string = "";
    updated_date: string = "";
}

export interface ConfirmationVehicleInterface {
    brand_id: number;
    model: string;
    frame_number: string;
    machine_number: string;
    plate_number: string;
    warna: string;
    sum_insured: string;
    state_id: string;
    city_id: string;
}

export class ConfirmationVehicleModel extends BaseModel implements ConfirmationVehicleInterface {
    brand_id: number = 0;
    model: string = "";
    frame_number: string = "";
    machine_number: string = "";
    plate_number: string = "";
    warna: string = "";
    sum_insured: string = "";
    state_id: string = "";
    city_id: string = "";
}

export interface InsuranceTypeInterface {
    insurance_type_id: string;
    insurance_type_name_ind: string;
    insurance_type_name_eng: string;
    alias: string;
    status: string;
    is_deleted: string;
    created_by: string;
    created_date: string;
    updated_by: string | null;
    updated_date: string | null;
    deleted_date: string | null;
}

export class InsuranceTypeModel extends BaseModel implements InsuranceTypeInterface {
    insurance_type_id: string = "";
    insurance_type_name_ind: string = "";
    insurance_type_name_eng: string = "";
    alias: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string = "";
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string | null = null;
    deleted_date: string | null = null;
}

export interface PaymentChannelInterface {
    channel_id: string;
    payment_gateway_id: string;
    channel_code: string;
    channel_name_ind: string;
    channel_name_eng: string;
    mdr: string | null;
    bank_fee: string;
    pg_fee: string | null;
    payment_name: string;
    mid: string;
    shared_key: string;
    mid_dev: string | null;
    shared_key_dev: string | null;
    link: string | null;
    link_dev: string | null;
    code: string | null;
    clear_key: string | null;
}

export class PaymentChannelModel extends BaseModel implements PaymentChannelInterface {
    channel_id: string = "";
    payment_gateway_id: string = "";
    channel_code: string = "";
    channel_name_ind: string = "";
    channel_name_eng: string = "";
    mdr: string | null = null;
    bank_fee: string = "";
    pg_fee: string | null = null;
    payment_name: string = "";
    mid: string = "";
    shared_key: string = "";
    mid_dev: string | null = null;
    shared_key_dev: string | null = null;
    link: string | null = null;
    link_dev: string | null = null;
    code: string | null = null;
    clear_key: string | null = null;
}

export interface CheckoutPaymentInterface {
    enc_trx_id: string;
    channel: PaymentChannelModel;
}

export class CheckoutPaymentModel extends BaseModel implements CheckoutPaymentInterface {
    enc_trx_id: string = "";
    channel: PaymentChannelModel = new PaymentChannelModel;
}
