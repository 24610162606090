import { Toaster } from "@/constant/Toaster";
import productKecelakaandiriService from "@/services/product.kecelakaandiri.service";
import { RootStateModel } from "@/store/types";
import { ActionTree } from "vuex";
import { ProductKecelakaanDiriStateModel } from "./types";

const actions: ActionTree<ProductKecelakaanDiriStateModel, RootStateModel> = {
    GetKecelakaanDiri({ state, commit }, params: any) {
        state.onLoad = true;
        return new Promise((success, error) => {
            productKecelakaandiriService.GetKecelakaanDiri().then((res) => {
                commit("set-content", res.data);
                commit("set-packages", res.data.packages);
                success(res);
            }).catch(error).finally(() => state.onLoad = false);
        });
    },
    CompareAction({ state, commit }, form: FormData) {
        state.onLoad = true;
        return new Promise((success, error) => {
            if (!(form instanceof FormData)) {
                form = new FormData
                Object.keys(state.form).forEach((key) => form.append(key, state.form[key]))
                localStorage.setItem('kecelakaandiri-history', JSON.stringify(state.form));
            }
            productKecelakaandiriService.GetData(form).then((res) => {
                if (typeof res.data.content === 'string') {
                    Toaster.Make(res.data.content, { variant: 'info', title: "Info" });
                    error(new Error(res.data.content))
                } else {
                    commit("set-list-data", res.data.content);
                    success(res.data.content);
                }
            }).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetSummary({ state, commit }, quotation_id: string) {
        return new Promise((success, error) => {
            state.onLoad = true
            productKecelakaandiriService.Summary(quotation_id).then((res) => {
                commit("set-summary", res.data);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        })
    },
    UseVoucher({ state, commit }, params: {quotation_id: string, form: FormData}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.UseVoucher(params.quotation_id, params.form).then((res) => {
                if (res.status.is_valid) {
                    commit('set-summary', res.data);
                    success(res)
                }else error(res)
            }).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetUlasan({ state, commit }, quotation_id: string) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.GetUlasan(quotation_id).then((res) => {
                if (res?.data) commit('set-product-testimony', res.data);
                else console.warn("ADDITIONALS COVER 'motor' not found!");
                return res;
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetBenefitCaries({ state, commit }, params?: string) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.GetBenefitCaries(params).then((res) => {
                if (res?.data) commit('set-benefit-cary', res.data?.listdata??[]);
                else console.warn("ADDITIONALS COVER 'motor' not found!");
                return res;
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    BuySubmitDetailProduct({ state, commit }, {form, quotation_id}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.AddDetailProduct(form, quotation_id).then((res) => {
                if (res?.status?.is_valid) success(res)
                else {
                    if(typeof res.data[0] === 'string' ) {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    BuySubmitPersonalDetailProduct({ state, commit }, {form, quotation_id}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.AddPersonalProduct(form, quotation_id).then((res) => {
                if (res?.status?.is_valid) success(res)
                else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetPaymentConfirmProduct({ state, commit }, quotation_id) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.ConfirmProduct(quotation_id).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-payment-confirmation", res.data)
                    success(res)
                } else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    CheckoutProduct({ state, commit }, quotation_id) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKecelakaandiriService.ConfirmCheckout(quotation_id).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-payment-confirmation", res.data)
                    success(res)
                } else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },

};

export default actions;
