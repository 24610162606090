import { UserModel } from "@/store/auth/types";
import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import { ServerResponse } from "./response.types";

interface LoginParams { device_id: string, email: string, password: string; }

class AuthenticationService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}`;
    }

    /**
     * GetToken
     */
    public GetToken(params: GetTokenParams): Promise<ServerResponse> {
        let self = new AuthenticationService
        let form = new FormData;
        Object.keys(params).forEach((key) => form.append(key, (params as any)[key]));
        return self.Base({ hideError: true }).post('generate-key', form);
    }

    /**
     * Login
    */
    public Login(params: UserModel): Promise<ServerResponse> {
        let self = new AuthenticationService
        let form = params.GetOnly(['email', 'password', 'device_id'])
        // form.password = btoa(form.password)
        // let form = new FormData
        // Object.keys(params).forEach((key)=>form.append(key, params[key]))
        return self.Base().post('get-token-user', form);
    }

    /**
     * register
     */
    public register(params: any): Promise<ServerResponse> {
        let self = new AuthenticationService
        return self.Base().post('login/register/submit', params);
    }

   /**
    * forgotPassword
    */
   public forgotPassword(params: any): Promise<ServerResponse> {
    let self = new AuthenticationService
    return self.Base().post('forgot-password', params);
   }

    /**
     * CheckuserAuth
     */
    public CheckuserAuth(params?: any): Promise<ServerResponse> {
        let self = new AuthenticationService
        return self.Base({hideError: true}).get('is-logged-in', params);
    }

    /**
     * ActivateAccount
     */
    public ActivateAccount(activation_code: string): Promise<ServerResponse> {
        let self = new AuthenticationService
        return self.Base().get('login/activate', {params: {activate_key: activation_code}})
    }
}

export default new AuthenticationService;
