import { RootStateModel, RootStateType } from "@/store/types";
import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import { ServerResponse } from "./response.types";

class ProfileTransactionService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}/profile/transaction`;
    }

    /**
     * GetHisotry
     */
    public GetHisotry(params: any, rootState: RootStateModel): Promise<ServerResponse> {
        let self = new ProfileTransactionService

        let form = new FormData
        form.append("customer_email", rootState.auth.user.email)
        if (params instanceof FormData) form.append('limit', "10")
        else if (typeof params === 'object') {
            params.limit = 10
            Object.keys(params).forEach(( key ) => form.append(key, params[key]))
        }

        return self.Base().post('', form);
    }

   /**
    * GetDetailHistory
    */
   public GetDetailHistory(trx_id: string) {
        let self = new ProfileTransactionService

        return self.Base().get(`details/${trx_id}`);
   }

}

export default new ProfileTransactionService;
