import locationService from "@/services/location.service";
import rootService from "@/services/root.service";
import { ActionTree } from "vuex";
import { RootStateType } from "../types";
import { LocationStateInterface } from "./types";

const actions: ActionTree<LocationStateInterface, RootStateType> = {
    GetCountry({state, commit}, params: any) {
        return new Promise((success, error) => {
            locationService.GetCountry(params).then((res) => {
                if(res.status.is_valid) {
                    commit("set-country", res.data.listdata)
                    success(res)
                }else error(new Error(res.messages))
            })
        })
    },
    GetProvince({state, commit}, params: any) {
        return new Promise((success, error) => {
            locationService.GetProvince(params).then((res) => {
                if(res.status.is_valid) {
                    commit("set-province", res.data.listdata)
                    success(res)
                }else error(new Error(res.messages))
            })
        })
    },
    GetCity({state, commit}, params: any) {
        return new Promise((success, error) => {
            locationService.GetCity(params).then((res) => {
                if(res.status.is_valid) {
                    commit("set-city", res.data.listdata)
                    success(res)
                }else error(new Error(res.messages))
            })
        })
    },
}

export default actions
