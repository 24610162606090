import { RootStateModel } from "@/store/types";
import { Module } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import { ProductKesehatanStateModel } from "./types";

const property: Module<ProductKesehatanStateModel, RootStateModel> = {
    namespaced: true,
    state: new ProductKesehatanStateModel(),
    actions,
    mutations
}


export default property
