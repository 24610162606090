import profileTransactionService from "@/services/profile.transaction.service";
import rootService from "@/services/root.service";
import { ActionTree } from "vuex";
import { RootStateModel, RootStateType } from "../types";
import { TransactionStateModel } from "./types";

const actions: ActionTree<TransactionStateModel, RootStateType> = {
    GetTransactionHistory({ state, rootState, commit }, params: any) {
        return new Promise((success, error) => {
            if (state.OnLoad) success(null)
            else {
                state.OnLoad = true

                profileTransactionService.GetHisotry(params, rootState as RootStateModel).then((response) => {
                    if (params) commit('append-history', response.data)
                    else commit("set-history", response.data)

                    success(response)
                }).catch(error).finally(()=>state.OnLoad=false)
            }

        })
    },
    GetTransactionDetail({ state, commit }, trx_id: string) {
        state.OnLoad = true
        return new Promise((success, error) => {
            profileTransactionService.GetDetailHistory(trx_id).then((response) => {
                commit("set-detail-history", response.data)
                success(response)
            }).catch(error).finally(() => state.OnLoad = false)
        })
    }
}

export default actions
