import BaseService, { ServiceBaseOptions } from ".";
import { ServerResponse } from "./response.types";

class RootService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}`;
    }

    /**
     * GetProvider
    */
    public GetProvider(): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().post('get-provider');
    }

    /**
     * GetProvider
    */
    public GetPartner(): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().post('partners');
    }

    /**
     * GetAbout
    */
    public GetAbout(): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().get('get-about');
    }

    /**
     * GetFeatureBy
    */
    public GetFeatureBy(): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base({ api: process.env.VUE_APP_API_URL }).get('get-feature-by');
    }

    /**
     * GetTentangRajapremi
     */
    public GetTentangRajapremi(): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().get('tentang-rajapremi');
    }

    /**
     * SetNewsTeller
     */
    public SetNewsteller(params: { email: string; }): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().post('general/newsletter', params);
    }

    /**
     * GetEventAndPromo
     */
    public GetEventAndPromo(params?: { email: string; }): Promise<ServerResponse> {
        let self = new RootService;
        return self.BaseService().get("admin/posts", { params });
    }

    /**
     * GetConfig
     */
    public GetConfig(): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().get("home/get_config");
    }

    /**
     * GetTestimony
     */
    public GetTestimony() {
        let self = new RootService;
        return self.Base().get("get-testimony");
    }

    /**
     * AddUlasan
     */
    public AddUlasan(form: FormData): Promise<ServerResponse> {
        let self = new RootService;
        return self.Base().post("/home/add_review", form);
    }

    /**
     * CallMe
    */
    public CallMe(params: FormData): Promise<ServerResponse> {
        let self = new RootService
        return self.Base().post(`call-me`, params)
    }


    /**
     * GetPageContent
    */
    public GetPageContent(): Promise<ServerResponse> {
        let self = new RootService
        return self.BaseService({validate_status_code: true}).get(`page-content`)
    }

    /**
     * GetFAQGroup
    */
    public GetFAQGroup(): Promise<ServerResponse> {
        let self = new RootService
        return self.BaseService({validate_status_code: true}).get(`admin/faq/faq-groups`)
    }

    /**
     * GetFAQItems
    */
    public GetFAQItems(): Promise<ServerResponse> {
        let self = new RootService
        return self.BaseService({validate_status_code: true}).get(`admin/faq/faq-items`)
    }

    /**
     * helpsubmit
     */
    public helpSubmit(form: FormData) {
        let self = new RootService
        return self.Base().post(`klaim-asuransi/submit`, form)
    }
}

export default new RootService;
