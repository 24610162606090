import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import ProductMotorService from "./product.motor.service";
import { ServerResponse } from "./response.types";

class ProductService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}`;
    }

    /**
     * GetProvider
    */
    public GetProvider(): Promise<ServerResponse> {
        let self = new ProductService
        return self.Base().post('get-provider');
    }

    /**
     * GetProvider
    */
    public GetPaymentOption(): Promise<ServerResponse> {
        let self = new ProductService
        return self.Base().get('payment/payment_list');
    }

    /**
     * GetProvider
    */
    public Buy(trx_id: string, form: FormData): Promise<ServerResponse> {
        let self = new ProductService
        return self.Base().post(`payment/choose/${trx_id}`, form);
    }

}

export default new ProductService;
