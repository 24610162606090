import { MutationTree } from "vuex";
import { TransactionHistoryInterface, TransactionStateModel, TransactionModel, DetailHistoryInterface, TransactionLogModel } from "./types";

const mutations: MutationTree<TransactionStateModel> = {
    "set-history": (state, data: TransactionHistoryInterface) => {
        state.history.total = data.total
        state.history.listdata = data.listdata.map((item) => (new TransactionModel).SetRawAttributes(item))
    },
    "append-history": (state, data: TransactionHistoryInterface) => {
        state.history.listdata.push(...data.listdata.map((item) => (new TransactionModel).SetRawAttributes(item)))
    },
    "set-detail-history": (state, data: DetailHistoryInterface) => {
        state.detail_hisory.profile.SetRawAttributes(data.profile)
        state.detail_hisory.product.SetRawAttributes(data.product)
        state.detail_hisory.provider.SetRawAttributes(data.provider)
        state.detail_hisory.insurance_type.SetRawAttributes(data.insurance_type)
        state.detail_hisory.transaction.SetRawAttributes(data.transaction)
        state.detail_hisory.page_title = data.page_title;
        state.detail_hisory.site_lang = data.site_lang;
        state.detail_hisory.verify = data.verify;
        state.detail_hisory.log = data.log?.map((item) => (new TransactionLogModel).SetRawAttributes(item));
    }
}

export default mutations
