import { LAYOUTS, PRODUCTS } from "@/constant/Enums";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: LAYOUTS.AUTHENTICATION
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register.vue"),
    meta: {
      layout: LAYOUTS.AUTHENTICATION
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: LAYOUTS.AUTHENTICATION
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutUs.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/Product.vue"),
    children: [...PRODUCTS, {
      path: '*',
      name: null,
      component: () => import("@/views/products/productNotFound.vue")
    }].map((product) => {
      const nameNoWhitespace = (product.name || 'NotFound').replace(' ', '')
      const nameLowercase = (product.name || 'NotFound').toLowerCase()

      const map:any = {
        path: product.name?nameLowercase.replace(' ', '-'):"*",
        component: () => import(`@/views/products/product${nameNoWhitespace}`)
      }

      if (product.name) map.name = nameLowercase.replace(' ', '')

      return map
    }),
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("@/views/Partner.vue"),
  },
  {
    path: "/partner/:id",
    name: "partner-detail",
    component: () => import("@/views/partner/detail.vue"),
  },
  {
    path: "/partner/:id/detail",
    name: "partner-ability-detail",
    component: () => import("@/views/partner/detailFull.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/Help.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/FAQ.vue"),
  },
  {
    path: "/profile",
    // name: "profile-root",
    component: () => import("@/components/layouts/ProfileLayout.vue"),
    meta: {
      layout: LAYOUTS.PROFILE
    },
    children: [
      {
        path: "",
        name: "profile",
        component: () => import('@/views/profile/info.vue')
      },
      {
        path: "change-password",
        name: "profile-change-password",
        component: () => import('@/views/profile/changePassword.vue')
      },
      {
        path: "transaction",
        // name: "profile-transaction",
        component: () => import('@/views/profile/transaction.vue'),
        children: [
          {
            path: "",
            name: "transaction-history",
            component: () => import('@/views/profile/transactions/history.vue')
          },
          {
            path: "finish",
            name: "transaction-finish",
            component: () => import('@/views/profile/transactions/finish.vue')
          },
          {
            path: "cancel",
            name: "transaction-cancel",
            component: () => import('@/views/profile/transactions/cancel.vue')
          },
          {
            path: "detail/:transaction_id",
            name: "transaction-detail",
            component: () => import('@/views/profile/transactions/detail.vue')
          },
        ]
      },
      {
        path: "address-book",
        name: "address-book",
        component: () => import('@/views/profile/addressBook.vue')
      },
      {
        path: "attendance",
        name: "attendance",
        component: () => import('@/views/profile/attendanceList.vue')
      },
      {
        path: "bank",
        name: "bank",
        component: () => import('@/views/profile/bankList.vue')
      },
      {
        path: "afiliation",
        name: "afiliation",
        component: () => import("@/views/afiliation/index.vue"),
        children: [
          {
            path: "information",
            name: "afiliation-information",
            component: () => import("@/views/afiliation/agreement.vue")
          },
          {
            path: "agreement",
            name: "afiliation-agreement",
            component: () => import("@/views/afiliation/balance.vue")
          },
          {
            path: "statistic",
            name: "afiliation-statistic",
            component: () => import("@/views/afiliation/information.vue")
          },
          {
            path: "transaction",
            name: "afiliation-transaction",
            component: () => import("@/views/afiliation/statistic.vue")
          },
          {
            path: "balance",
            name: "afiliation-balance",
            component: () => import("@/views/afiliation/transaction.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/compare",
    name: "compare",
    component: () => import("@/views/Compare.vue"),
    children: [
      ...PRODUCTS.map((product) => {
        const nameNoWhitespace = (product.name || 'NotFound').replace(' ', '')
        const nameLowercase = (product.name || 'NotFound').toLowerCase()
        const nameLowercaseMin = nameLowercase.replace(' ', '')
        const pathName = product.name?nameLowercase.replace(' ', '-'):"*"

        const mapCompareItems: RouteConfig = {
          path: pathName,
          component: () => import(`@/views/compare/compare${nameNoWhitespace}`),
          meta: { ...product, ...{route_detail_name: `detail-item-${nameLowercaseMin}`}},
        }
        if (product.name) mapCompareItems.name = 'compare-'+nameLowercaseMin

        return mapCompareItems
      }),
      ...PRODUCTS.map((product) => {
        const nameMin = (product.name || 'NotFound').replace(' ', '')
        const nameLowercase = (product.name || 'NotFound').toLowerCase()
        const nameLowercaseMin = nameLowercase.replace(' ', '')
        const pathName = product.name?nameLowercase.replace(' ', '-'):"*"

        const mapCompareItemDetails: RouteConfig = {
          path: `${pathName}/detail`,
          meta: product,
          component: () => import('@/views/compare/detail.vue'),
          children: [
            {
              path: '',
              name: `detail-item-${nameLowercaseMin}`,
              component: () => import(`@/views/compare/segments/detail/detail${nameMin}.vue`)
            },
            {
              path: 'buy',
              name: `buy-item-${nameLowercaseMin}`,
              component: () => import(`@/views/compare/segments/buy/buy${nameMin}.vue`)
            },
            {
              path: 'user-info',
              name: `info-item-${nameLowercaseMin}`,
              component: () => import(`@/views/compare/segments/info/userInfo${nameMin}.vue`)
            },
            {
              path: 'personalinfo',
              name: `personal-item-${nameLowercaseMin}`,
              component: () => import(`@/views/compare/segments/personal/personal${nameMin}.vue`)
            }
          ]
        }

        return mapCompareItemDetails
      })
    ],
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
