











  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, Mutation, State } from 'vuex-class';
  import { LAYOUTS } from './constant/Enums';
  import { GetTokenParams } from './services/params.types';
  import { ServerResponse } from './services/response.types';

  @Component({
    name: 'App',
    components: {
      GeneralLayout: () => import('@/components/layouts/GeneralLayout.vue'),
      AuthLayout: () => import('@/components/layouts/AuthLayout.vue'),
      ProfileLayout: () => import('@/components/layouts/ProfileLayout.vue'),
    },
    mixins: [],
  })
  export default class App extends Vue {
    @Action('GetToken') InitService!: (params?: GetTokenParams) => Promise<ServerResponse>;
    @Action('GetPageContent') GetPageContent!: () => Promise<ServerResponse>;

    @State('OnLoad') OnLoad!: boolean;

    @State('CurrentLayout') layout!: LAYOUTS;

    @Mutation('set-layout') setLayout!: (layout: LAYOUTS) => void;

    public accessTokenLoaded = false

    public get currentLayout(): string {
      switch (this.$route.meta?.layout) {
        case LAYOUTS.GENERAL:
          return 'GeneralLayout';
        case LAYOUTS.AUTHENTICATION:
          return 'AuthLayout';
        case LAYOUTS.PROFILE:
          return 'ProfileLayout';
        default:
          return 'GeneralLayout';
      }
    }

    public created(): void {
      this.InitService().then((res)=>{
        this.accessTokenLoaded = true
      })
      this.GetPageContent()
    }

    public mounted(): void {
      // on componenet mounted
    }
  }
