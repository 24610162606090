import { BaseModel } from "@/constant/BaseModel";
import { UserModel } from "@/store/auth/types";
import { BSelectOption } from "@/store/types";
import { ProductDataInterface, ProductDataModel, ProductInterface, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, ProductTestimonyModel } from "../types";

export interface ProductKecelakaanDiriStateInterface {
    onLoad: boolean;
    title: string;
    content: string;
    banner: string;
    package: PackageInterface[];
}

export class ProductKecelakaanDiriStateModel extends BaseModel implements ProductKecelakaanDiriStateInterface {
    onLoad: boolean = false;
    title: string = "";
    content: string = "";
    banner: string = "";
    package: PackageModel[] = [];
    form: FormCompareKecelakaanDiriInterface = new FormCompareKecelakaanDiriModel;
    data: ProductDataInterface = new ProductDataModel;
    summary: ProductSummaryInterface = new ProductSummaryModel;
    benefit_caries: BenefitCaryModel[] = [];
    testimony: ProductTestimonyInterface = new ProductTestimonyModel;
    confirmation: PaymentProductKecelakaanDiriConfirmationInterface = new PaymentProductKecelakaanDiriConfirmationModel;
}

export interface PackageInterface {
    pa_package_id: string;
    package_name_ind: string;
    package_name_eng: string;
    seq: string;
    top_limit: string;
    bottom_limit: string;
    status: string;
    is_deleted: string;
    created_by: string;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
    dtm: string;
}

export interface FormCompareKecelakaanDiriInterface {
    type: string;
    package: number;
    sort: string;
}

export class FormCompareKecelakaanDiriModel extends BaseModel implements FormCompareKecelakaanDiriInterface {
    type: string = '';
    package: number = 0;
    sort: string = 'a-z';
}

export class PackageModel extends BaseModel implements PackageInterface {
    pa_package_id: string = "";
    package_name_ind: string = "";
    package_name_eng: string = "";
    seq: string = "";
    top_limit: string = "";
    bottom_limit: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string = "";
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
    dtm: string = "";
}

export interface PaymentProductKecelakaanDiriConfirmationInterface {
    relation: BenefitCaryRelationModel;
    product: ProductInterface;
    user: UserModel;

}

export class PaymentProductKecelakaanDiriConfirmationModel extends BaseModel implements PaymentProductKecelakaanDiriConfirmationModel {
    relation: BenefitCaryRelationModel = new BenefitCaryRelationModel
    product: ProductModel = new ProductModel;
    user: UserModel = new UserModel
}

export interface BenefitCaryInterface {
    beneficiary_id: string;
    beneficiary_name_eng: string;
    beneficiary_name_ind: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
    dtm: string;
}

export class BenefitCaryModel extends BaseModel implements BenefitCaryInterface {
    beneficiary_id: string = "";
    beneficiary_name_eng: string = "";
    beneficiary_name_ind: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
    dtm: string = "";
}

export interface BenefitCaryRelationInterface {
    beneficiary_id: string;
    beneficiary_name_eng: string;
    beneficiary_name_ind: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
    dtm: string;
}

export class BenefitCaryRelationModel extends BaseModel implements BenefitCaryRelationInterface {
    beneficiary_id: string = "";
    beneficiary_name_eng: string = "";
    beneficiary_name_ind: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
    dtm: string = "";
}
