import { INSURANCE_TYPE } from "@/constant/Enums";
import { Toaster } from "@/constant/Toaster";
import productMotorService from "@/services/product.motor.service";
import { ServerResponse } from "@/services/response.types";
import { RootStateModel, RootStateType } from "@/store/types";
import { ActionTree } from "vuex";
import { ProductMotorState, ProductMotorStateInterface } from "./types";

const actions: ActionTree<ProductMotorState, RootStateModel> = {
    GetMotor({ state, commit }, params: any) {
        return new Promise((success, error) => {
            productMotorService.GetMotor().then((res) => {
                commit("set-content", res.data)
                success(res)
            })
        })
    },
    GetPlates({ state, commit }, params: any) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.GetPlates().then((res) => {
                commit("set-plates", res.data.plates);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        });
    },
    CompareAction({ state, commit }, payload: any) {
        return new Promise((success, error) => {
            state.onLoad = true;
            let form:any = {}
            if (payload instanceof FormData) {
                form = payload
            } else {
                state.form.insurance_type = INSURANCE_TYPE.MOTORCYCLE_INSURANCE;
                state.form.sort = payload?.sort || 'lowest';
                form = state.form
            }
            productMotorService.GetData(form).then((res) => {
                commit("set-list-data", res.data);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        });
    },
    CompareProductAction({ state, commit }, payload: FormData) {
        return new Promise((success, error) => {
            productMotorService.Compare(payload).then((res) => {
                commit("set-list-compare", res.data.contents);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetSummary({ state, commit }, quotation_id) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.GetSummary(quotation_id).then((res) => {
                commit('set-summary', res.data);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetAdditional({ state, commit }, params?: any) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.GetAdditionalCover(params).then((res) => {
                if (res?.data?.additionals) commit('set-additional-cover', res.data.additionals);
                else console.warn("ADDITIONALS COVER 'motor' not found!");
                return res;
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    },
    UseVoucher({ state, commit }, params: {quotation_id: string, form: FormData}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.UseVoucher(params.quotation_id, params.form).then((res) => {
                if (res.status.is_valid) {
                    commit('set-summary', res.data);
                    success(res)
                }else error(res)
            }).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetUlasan({ state, commit }, quotation_id: string) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.GetUlasan(quotation_id).then((res) => {
                if (res?.data) commit('set-product-testimony', res.data);
                else console.warn("ADDITIONALS COVER 'motor' not found!");
                return res;
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    },
    BuySubmitDetailProduct({ state, commit }, {form, quotation_id}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.AddDetailProduct(form, quotation_id).then((res) => {
                if (res?.status?.is_valid) success(res)
                else {
                    if(typeof res.data[0] === 'string' ) {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    },
    BuySubmitPersonalDetailProduct({ state, commit }, {form, quotation_id}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.AddPersonalProduct(form, quotation_id).then((res) => {
                if (res?.status?.is_valid) success(res)
                else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetPaymentConfirmProduct({ state, commit }, quotation_id) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.ConfirmProduct(quotation_id).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-payment-confirmation", res.data)
                    success(res)
                } else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    },
    CheckoutProduct({ state, commit }, quotation_id) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.ConfirmCheckout(quotation_id).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-payment-confirmation", res.data)
                    success(res)
                } else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetBrands({ state, commit }, quotation_id: string) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productMotorService.GetBrands(quotation_id).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-brands", res.data.brands.listdata)
                    success(res);
                }else {
                    if(typeof res.data[0] === 'string') {
                        // let span = document.createElement('span')
                        // span.innerHTML = res.data[0]
                        // res.data[0] = span.textContent || span.innerText
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then(() => success(state.additional_cover)).catch(error).finally(() => state.onLoad = false);
        });
    }

};

export default actions;
