export class BaseModel {
    constructor() {

    }

    /**
     * ### SetRawAttributes
     * #### development info
     *
     * |     INFO    |      Description                     |
     * |:------------|:-------------------------------------|
     * | email       |sutha.purwatha@frisidea.com           |
     * | created     |Monday, 14 February 2022 at 15:31   |
     * | modified    |                                      |
     *
     * @param attributes any
     * @return this model refference
    */
    public SetRawAttributes(attributes: any, ) {
        if (attributes) {
            const selfAttrs = Object.keys(this).filter(v => typeof (this as any)[v] !== 'function')
            const sources: any = {}
            Object.keys(attributes).forEach((v, k) => sources[v] = attributes[v])
            if(Object.keys(sources).length>0) Object.assign(this, sources)
        }
        return this
    }

    /**
     * get only some of this property
     * @param string[] attribute name
     */
    public GetOnly(attributes:string[] = []) {
        let visible:any = {}
        if (attributes) {
            attributes.forEach((key) => visible[key] = this[key])
        }
        return visible
    }
}
