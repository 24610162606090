import { Toaster } from "@/constant/Toaster";
import productKesehatanService from "@/services/product.kesehatan.service";
import { RootStateModel } from "@/store/types";
import { ActionTree } from "vuex";
import { ProductKesehatanStateModel } from "./types";

const actions: ActionTree<ProductKesehatanStateModel, RootStateModel> = {
    GetKesehatan({ state, commit }, params: any) {
        state.onLoad = true
        return new Promise((success, error) => {
            productKesehatanService.GetKesehatan().then((res) => {
                commit("set-content", res.data)
                commit("location/set-province", res.data.states, {root: true})
                success(res)
            }).catch(error).finally(() => state.onLoad = false)
        })
    },
    CompareAction({ state, commit }, form: FormData) {
        state.onLoad = true
        return new Promise((success, error) => {
            if (!form) {
                if (Number(state.form.participant_status) === 1 && Number(state.form.child_status) === 1) state.form.participant_status = 3;
                if([1,3].indexOf(Number(state.form.participant_status))>=0) state.form.bod_individu = state.form.bod_husband

                form = new FormData();
                Object.keys(state.form).forEach((key) => {
                    if(state.form[key]) {
                    if(state.form[key] instanceof Array) state.form[key].forEach((item, i) => {form.append(`${key}[${i}]`, item)});
                    else form.append(key, state.form[key])
                    }
                })

            }

            productKesehatanService.GetData(form).then((res) => {
                commit("set-list-data", res.data)
                success(res)
            }).catch(error).finally(() => state.onLoad = false)
        })
    },
    CompareProductAction({ state, commit }, payload: FormData) {
        return new Promise((success, error) => {
            productKesehatanService.Compare(payload).then((res) => {
                commit("set-list-compare", res.data.contents);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetSummary({ state, commit }, quotation_id: string) {
        return new Promise((success, error) => {
            state.onLoad = true
            productKesehatanService.Summary(quotation_id).then((res) => {
                commit("set-summary", res.data);
                return res;
            }).then(success).catch(error).finally(() => state.onLoad = false);
        })
    },
    UseVoucher({ state, commit }, params: {quotation_id: string, form: FormData}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKesehatanService.UseVoucher(params.quotation_id, params.form).then((res) => {
                if (res.status.is_valid) {
                    commit('set-summary', res.data);
                    success(res)
                }else error(res)
            }).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetUlasan({ state, commit }, quotation_id: string) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKesehatanService.GetUlasan(quotation_id).then((res) => {
                if (res?.data) commit('set-product-testimony', res.data);
                else console.warn("ADDITIONALS COVER 'motor' not found!");
                return res;
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    BuySubmitDetailProduct({ state, commit }, {form, quotation_id}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKesehatanService.AddDetailProduct(form, quotation_id).then((res) => {
                if (res?.status?.is_valid) success(res)
                else {
                    if(typeof res.data[0] === 'string' ) {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    BuySubmitPersonalDetailProduct({ state, commit }, {form, quotation_id}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKesehatanService.AddPersonalProduct(form, quotation_id).then((res) => {
                if (res?.status?.is_valid) success(res)
                else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    GetPaymentConfirmProduct({ state, commit }, quotation_id) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKesehatanService.ConfirmProduct(quotation_id).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-payment-confirmation", res.data)
                    success(res)
                } else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
    CheckoutProduct({ state, commit }, params:{quotation_id: string, form: FormData}) {
        return new Promise((success, error) => {
            state.onLoad = true;
            productKesehatanService.ConfirmCheckout(params.quotation_id, params.form).then((res) => {
                if (res?.status?.is_valid) {
                    commit("set-payment-option", res.data)
                    success(res)
                } else {
                    if(typeof res.data[0] === 'string') {
                        res.data[0] = res.data[0].HTMLtoString()
                    }
                    Toaster.Warning(res.data[0]||res.messages);
                }
                return res
            }).then((res) => success(res)).catch(error).finally(() => state.onLoad = false);
        });
    },
};

export default actions;
