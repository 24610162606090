import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import { ServerResponse } from "./response.types";

class LocationService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}`;
    }

    public GetCountry(payload: any): Promise<ServerResponse> {
        let self = new LocationService
        let params = {
            page : payload?.page||1,
            limit : payload?.limit||10
        }
        return self.Base().post(`country/get_list`, params )
    }

    public GetProvince(payload: any): Promise<ServerResponse> {
        let self = new LocationService
        let params = {
            page : payload?.page||1,
            limit : payload?.limit||10
        }
        return self.Base().post(`states/get_list`, params )
    }

    public GetCity(payload: any): Promise<ServerResponse> {
        let self = new LocationService
        payload.page = payload?.page||1,
        payload.limit = payload?.limit||10
        return self.Base().post(`city/get_list`, payload )
    }

}

export default new LocationService
