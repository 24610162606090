import { Toaster } from "@/constant/Toaster";
import authenticationService from "@/services/authentication.service";
import { GetTokenParams } from "@/services/params.types";
import rootService from "@/services/root.service";
import { ActionTree } from "vuex";
import { RootStateType } from "./types";
const { v4: uuidv4 } = require('uuid');

const actions: ActionTree<RootStateType, RootStateType> = {
    GetToken({ state }, payload: GetTokenParams) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            const uuid:string = uuidv4();

            authenticationService.GetToken({
                user_id: uuid.replace(/[-]/g,'')
            }).then((response) => {
                if (response.data.token) localStorage.setItem("token", response.data.token);
                else Toaster.Warning("cannot get token");
                success(response);
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetAbout({ state, commit }, pauload: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            rootService.GetAbout().then((v) => commit('set-about', v.data)).then(success).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetFeatureBy({ state, commit }, payload: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            rootService.GetFeatureBy().then((v) => commit('set-feature-by', v.data)).then(success).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetListTestimonials({ state, commit }, payload: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            rootService.GetTentangRajapremi().then((v) => commit('set-testimonial', v.data.testimonial)).then(success).catch(error).finally(() => state.OnLoad = false);
        });
    },
    SetNewsteller({state}, params) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            rootService.SetNewsteller(params).then((response) => {
                Toaster.Make(response.messages, {variant: 'success'})
            }).then(success).catch(error).finally(() => state.OnLoad = false);
        });
    },
    EventAndPromo({ state, commit}, params) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            rootService.GetEventAndPromo(params).then((res) => {
                commit('set-event-promo', res.data)
                success(res)
            }).catch((reason) => {
                // console.log(reason);

                error(error)
            }).finally(() => state.OnLoad = false);
        });
    },
    GetConfig({ state, commit }) {
        return new Promise((success, error) => {
            rootService.GetConfig().then((res) => commit('set-config', res.data.listdata)).then(success).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetTestimony({ state, commit }) {
        return new Promise((success, error) => {
            rootService.GetTestimony().then((res) => {
                commit('set-testimony', res.data)
                success(state.testimony)
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    AddUlasan({ state, commit }, form: FormData) {
        return new Promise((success, error) => {
            rootService.AddUlasan(form).then((res) => {
                if (res.status.is_valid) success(res)
                else Toaster.Warning(res.messages)
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    CallMe({ state }, params: FormData) {
        state.OnLoad = true
        return new Promise((success, error) => {
            rootService.CallMe(params).then((res) => {
                if (!res.status.is_valid) Toaster.Warning(res.messages);
                success(res)
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetPageContent({ state, commit }) {
        state.OnLoad = true
        return new Promise((success, error) => {
            rootService.GetPageContent().then((res) => {
                // console.log(res);
                if (res) {
                    commit("set-page-content", res)
                    success(res)
                }else console.warn("empty response");
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetFaqGroup({ state, commit }) {
        state.OnLoad = true
        return new Promise((success, error) => {
            rootService.GetFAQGroup().then((res) => {
                commit("set-faq-group", res.data)
                success(res)
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    GetFaqItems({ state, commit }) {
        state.OnLoad = true
        return new Promise((success, error) => {
            rootService.GetFAQItems().then((res) => {
                commit("set-page-items", res.data)
                success(res)
            }).catch(error).finally(() => state.OnLoad = false);
        });
    },
    SubmitHelp({ state }, form: FormData) {
        state.OnLoad = true
        return new Promise((success, error) => {
            rootService.helpSubmit(form).then(success).catch(error).finally(() => state.OnLoad = false);
        });
    },

};
export default actions;
