import { TestimonialModel } from "@/store/types";
import { MutationTree } from "vuex";
import { CompareContentInterface } from "../mobil/types";
import { ClauseDataSummaryModel, ConfirmationUserModel, ConfirmationVehicleModel, ProductAdditionalModel, ProductDataModel, ProductFeatureModel, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, RatingDetailModel, RatingModel } from "../types";
import { PaymentProductPropertiConfirmationModel, ProductPropertyStateModel } from "./types";

const mutations: MutationTree<ProductPropertyStateModel> = {
    "set-content": (state, data: any) => {
        state.title = data.page_title
        state.content = data.page_content
        state.banner = data.main_banner
    },
    "set-form": (state, data) => {
        state.form = data
    },
    "set-list-data": (state, data: ProductDataModel) => {
        if (data) {
            state.data.total = data.total

            state.data.listdata = []
            data.listdata.forEach((item) => {
                state.data.listdata.push((new ProductModel).SetRawAttributes(item))
            })
        }
    },
    "set-summary": (state, summary: ProductSummaryInterface) => {
        if (summary) {
            state.summary = (new ProductSummaryModel).SetRawAttributes(summary)
            state.summary.clause.listdata = state.summary.clause.listdata.map((item) => (new ClauseDataSummaryModel).SetRawAttributes(item))
            state.summary.feature.listdata = state.summary.feature.listdata.map((item) => (new ClauseDataSummaryModel).SetRawAttributes(item))
            state.summary.deductible.listdata = state.summary.feature.listdata.map((item) => (new ClauseDataSummaryModel).SetRawAttributes(item))
        }
    },
    "set-payment-confirmation": (state, confirmation: PaymentProductPropertiConfirmationModel) => {
        state.confirmation.product = (new ProductModel).SetRawAttributes(confirmation.product)
    },
    "set-product-testimony": (state, testimony: ProductTestimonyInterface) => {
        state.testimony.avg_rating = testimony?.avg_rating??0
        state.testimony.sum_rating = testimony?.sum_rating??0
        state.testimony.rating = testimony?.rating.map((item) => {
            let rating = (new RatingModel).SetRawAttributes(item)
            rating.data = (new RatingDetailModel).SetRawAttributes(item.data)
            return rating
        }).sort((a, b) => a.rating - b.rating)??[]

        state.testimony.list_testimonial = testimony?.list_testimonial.map((item) => {
            return (new TestimonialModel).SetRawAttributes(item)
        })??[]
    },
    "set-list-compare": (state, contents: CompareContentInterface[]) => {
        state.listCompare = contents?.map((item) => {
            let product = (new ProductModel).SetRawAttributes(item.product)
            product.feature = item.feature?.map((feature) => (new ProductFeatureModel).SetRawAttributes(feature))??[]
            product.additional = item.additional?.map((additional) => (new ProductAdditionalModel).SetRawAttributes(additional))??[]
            return {product}
        })
    },
}

export default mutations
