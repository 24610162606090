import { BaseModel } from "@/constant/BaseModel";
import { INSURANCE_TYPE, MOTOR_COVERAGE } from "@/constant/Enums";
import { CompareContentInterface, CompareContentModel } from "../mobil/types";
import { ConfirmationVehicleInterface, PlateModel, ProductBrandInterface, ProductDataInterface, ProductDataModel, ProductInterface, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, ProductTestimonyModel, ConfirmationUserInterface, ConfirmationUserModel, ConfirmationVehicleModel } from "../types";

export interface ProductMotorStateInterface {
    onLoad: boolean;
    title: string;
    content: string;
    banner: string
    plates: PlateModel[];
    brands: ProductBrandInterface[];
    discount_code: string | null;
    listCompare: CompareContentInterface[]
}

export class ProductMotorState extends BaseModel implements ProductMotorStateInterface {
    onLoad: boolean = false;
    title: string = "";
    content: string = "";
    banner: string = "";
    plates: PlateModel[] = [];
    brands: ProductBrandInterface[] = [];
    form: FormCompareMotorInterface = new FormCompareMotorModel;
    data: ProductDataInterface = new ProductDataModel;
    summary: ProductSummaryInterface = new ProductSummaryModel;
    additional_cover: AdditionalCoverInterface[] = [];
    listCompare: CompareContentInterface[] = []
    testimony: ProductTestimonyInterface = new ProductTestimonyModel;
    confirmation: PaymentProductMotorConfirmationInterface = new PaymentProductMotorConfirmationModel;
    discount_code: string|null = null
}

export interface FormCompareMotorInterface {
		[x: string]: any;
    /** total price */
    suminsured: number;

    /**
     * - 1 = all risk
     * - 2 = tlo
    */
    coverage: MOTOR_COVERAGE;

    /** year */
    year: number;

    /** use from /motor/get_plate_numbers */
    plate: string;

    /** number of enums INSURANCE_TYPE*/
    insurance_type: INSURANCE_TYPE;

    /** mandatory if additional is filled. default 0 */
    limit_person: number;

    /** mandatory if additional is filled. default 0 */
    limit_tpl: number;

    /** mandatory if additional is filled. default 0 */
    limit_pad: number;

    /** mandatory if additional is filled default 0 */
    limit_pap: number;

    /** use from /motor/get_additionals*/
    additional: number[] | null;

    /**
     * - lowest
     * - highest
     * - a-z
     * - z-a
    */
    sort: string;
}

export class FormCompareMotorModel extends BaseModel implements FormCompareMotorInterface {
    suminsured: number = 0;
    coverage: MOTOR_COVERAGE = MOTOR_COVERAGE.ALL_RISK;
    year: number = 1999;
    plate: string = '';
    insurance_type: INSURANCE_TYPE = INSURANCE_TYPE.MOTORCYCLE_INSURANCE;
    limit_person: number = 0;
    limit_tpl: number = 0;
    limit_pad: number = 0;
    limit_pap: number = 0;
    additional: number[] | null = null;
    sort: string = 'lowest';
}

export interface AdditionalCoverInterface {
    vehicle_additional_id: string;
    additional_category_name_ind: string;
    additional_category_name_eng: string;
    additional_category_alias: string;
    seq: string;
    type: string;
    is_car_only: string;
    status: string;
    is_deleted: string;
    created_by: string;
    created_date: string;
    updated_by: string;
    updated_date: string;
    dtm: string;
}

export class AdditionalCoverModel extends BaseModel implements AdditionalCoverInterface {
    vehicle_additional_id: string = "";
    additional_category_name_ind: string = "";
    additional_category_name_eng: string = "";
    additional_category_alias: string = "";
    seq: string = "";
    type: string = "";
    is_car_only: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string = "";
    created_date: string = "";
    updated_by: string = "";
    updated_date: string = "";
    dtm: string = "";
}

export interface PaymentProductMotorConfirmationInterface {
    product: ProductInterface
    promo_code: string
    user: ConfirmationUserInterface
    vehicle: ConfirmationVehicleInterface
}
export class PaymentProductMotorConfirmationModel extends BaseModel implements PaymentProductMotorConfirmationInterface {
    product: ProductModel = new ProductModel;
    promo_code: string = "";
    user: ConfirmationUserModel = new ConfirmationUserModel;
    vehicle: ConfirmationVehicleModel = new ConfirmationVehicleModel;
}
