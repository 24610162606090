import { UserModel } from "@/store/auth/types";
import { TestimonialModel } from "@/store/types";
import { MutationTree } from "vuex";
import { BenefitInterModel, ClauseDataSummaryModel, ProductDataModel, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, RatingDetailModel, RatingModel } from "../types";
import { BenefitCaryInterface, BenefitCaryModel, BenefitCaryRelationModel, PackageInterface, PackageModel, PaymentProductKecelakaanDiriConfirmationInterface, ProductKecelakaanDiriStateModel } from "./types";

const mutations: MutationTree<ProductKecelakaanDiriStateModel> = {
    "set-content": (state, data: any) => {
        state.title = data.page_title
        state.content = data.page_content
        state.banner = data.main_banner
    },
    "set-packages": (state, packages: PackageInterface[]) => {
        state.package = packages?.map((item) => (new PackageModel).SetRawAttributes(item))
    },
    "set-form": (state, data) => {
        state.form = data
    },
    "set-list-data": (state, data: ProductDataModel) => {
        if (data) {
            state.data.total = data.total

            state.data.listdata = []
            data.listdata.forEach((item) => {
                state.data.listdata.push((new ProductModel).SetRawAttributes(item))
            })
        }
    },
    "set-summary": (state, summary: ProductSummaryInterface) => {
        if (summary) {
            state.summary = (new ProductSummaryModel).SetRawAttributes(summary)
            state.summary.benefit.listdata = state.summary.benefit.listdata.map((item) => (new BenefitInterModel).SetRawAttributes(item))
        }
    },
    "set-benefit-cary": (state, cary: BenefitCaryInterface[]) => {
        state.benefit_caries = cary.map((item)=>(new BenefitCaryModel).SetRawAttributes(item))
    },
    "set-payment-confirmation": (state, confirmation: PaymentProductKecelakaanDiriConfirmationInterface) => {
        state.confirmation.relation = (new BenefitCaryRelationModel).SetRawAttributes(confirmation.relation)
        state.confirmation.product = (new ProductModel).SetRawAttributes(confirmation.product)
        state.confirmation.user = (new UserModel).SetRawAttributes(confirmation.user)
    },
    "set-product-testimony": (state, testimony: ProductTestimonyInterface) => {
        state.testimony.avg_rating = testimony?.avg_rating??0
        state.testimony.sum_rating = testimony?.sum_rating??0
        state.testimony.rating = testimony?.rating?.map((item) => {
            let rating = (new RatingModel).SetRawAttributes(item)
            rating.data = (new RatingDetailModel).SetRawAttributes(item.data)
            return rating
        }).sort((a, b) => a.rating - b.rating)??[]

        state.testimony.list_testimonial = testimony?.list_testimonial?.map((item) => {
            return (new TestimonialModel).SetRawAttributes(item)
        })??[]
    },
}

export default mutations
