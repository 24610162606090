import { BaseModel } from "@/constant/BaseModel";
import { GENDER } from "@/constant/Enums";
const { v4: uuidv4 } = require('uuid');

export interface AuthStateType {
    OnLoad: boolean;
    OnLoadAddress: boolean;
    user: UserModel;
    address: AddressBookInterface[];
    bank: BankAccountInterface[];
}

export class AuthStateModel extends BaseModel implements AuthStateType {
    OnLoad: boolean = false;
    OnLoadAddress: boolean = false;
    user: UserModel = new UserModel;
    address: AddressBookInterface[] = [];
    bank: BankAccountInterface[] = [];
}

export interface UserType {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone: string;
    passconf?: string | null;
    full_name?: string | null;
    is_social_media: number;
    user_id: string;
    idcard: string;
    occupation: string;
    born_date: string;
    gender: string | null;
    verification_code: string;
    is_affiliate: string;
    affiliate_code: string;
    affiliate_type_id: string;
    city_id: string;
    state_id: string;
    country_id: string;
    last_login: string;
    sendinblue_sync: string | null;
    gcm_id: string | null;
    status: string;
    is_deleted: string;
    created_by: string;
    created_date: string;
    updated_by: string;
    updated_date: string;
    dtm: string;
}

export class UserModel extends BaseModel implements UserType {
    email: string = "";
    password: string = "";
    remember: boolean = false;
    first_name: string = "";
    last_name: string = "";
    phone: string = "";
    passconf?: string | null = null;
    full_name?: string | null = null;
    is_social_media: number = 0;
    device_id: string = uuidv4();
    user_id: string = "";
    idcard: string = "";
    occupation: string = "";
    born_date: string = "";
    gender: string | null = String(GENDER[0].value);
    verification_code: string = "";
    is_affiliate: string = "";
    affiliate_code: string = "";
    affiliate_type_id: string = "";
    city_id: string = "";
    state_id: string = "";
    country_id: string = "";
    last_login: string = "";
    sendinblue_sync: string | null = null;
    gcm_id: string | null = null;
    status: string = "";
    is_deleted: string = "";
    created_by: string = "";
    created_date: string = "";
    updated_by: string = "";
    updated_date: string = "";
    dtm: string = "";

    beneficiary_id?: string;
    beneficiary_name?: string;
}


export interface AddressBookInterface {
    address: string;
    address_id: string;
    city_id: string;
    country_id: string;
    created_by: string;
    created_date: string;
    is_deleted: string;
    phone: string;
    reciever: string;
    state_id: string;
    status: string;
    title_address: string;
    updated_by: string;
    updated_date: string;
    user_id: string;
    zipcode: string;
}

export interface BankAccountInterface {

}

export class AddressBookModel extends BaseModel implements AddressBookInterface {
    address: string = "";
    address_id: string = "";
    city_id: string = "";
    country_id: string = "";
    created_by: string = "";
    created_date: string = "";
    is_deleted: string = "";
    phone: string = "";
    reciever: string = "";
    state_id: string = "";
    status: string = "";
    title_address: string = "";
    updated_by: string = "";
    updated_date: string = "";
    user_id: string = "";
    zipcode: string = "";
    province_name?: string;
    city_name?: string;
}

export class BankAccountModel extends BaseModel implements BankAccountInterface {

}
