import authenticationService from "@/services/authentication.service";
import { ActionTree } from "vuex";
import { RootStateType } from "../types";
import { AuthStateModel, UserModel, UserType } from "./types";
import app from "@/main";
import { Toaster } from "@/constant/Toaster";
import profileService from "@/services/profile.service";

const actions: ActionTree<AuthStateModel, RootStateType> = {
    login({ state, rootState, dispatch }, form: UserModel) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            authenticationService.Login(form).then((response) => {
                if (response.status.is_valid) {
                    app.$cookies.set("SES", response.data.token);
                    success(response.data.token);
                } else {
                    Toaster.Warning(response.messages);
                    error(new Error(response.messages));
                }
            }).catch((reason) => error(reason));
        }).finally(() => rootState.OnLoad = false);
    },
    register({ state, rootState, dispatch }) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            // state.user.password = btoa(state.user.password);
            // state.user.passconf = btoa(state.user.passconf);
            let form = new FormData;
            Object.keys(state.user).forEach((key) => form.append(key, state.user[key]));
            authenticationService.register(form).then((response) => {
                if (response.status.is_valid) {
                    dispatch('login', state.user).then((token: string) => success(response))
                } else {
                    Toaster.Warning(response.messages);
                    error(new Error(response.messages));
                }
                // commit

            }).catch((reason) => error(reason));
        }).finally(() => rootState.OnLoad = false);
    },
    /**
     * activate account (optional)
     * > not used. when register account has been activated
    */
    ActivateAccount({ rootState, commit }, activation_code?: string) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            if (localStorage.getItem('token')) {
                authenticationService.ActivateAccount(activation_code).then(success).catch(error);
            } else error(new Error("token not found!"));
        }).finally(() => rootState.OnLoad = false);
    },
    CheckuserAuth({ rootState, commit, dispatch }, params?: UserType) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            if (localStorage.getItem('token')) {
                authenticationService.CheckuserAuth(params).then((response) => {
                    // commit('set-user', response.data.data)
                    dispatch("GetProfile");
                    success(response.data['logged-in']);
                }).catch(error);
            } else error(new Error("token not found!"));
        }).finally(() => rootState.OnLoad = false);
    },
    ChangePassword({ state }, params: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            if (localStorage.getItem('token')) {
                profileService.ChangePassword(params).then((response) => {
                    // app.$cookies.remove("SES")
                    success(response);
                }).catch(error);
            } else error(new Error("token not found!"));
        }).finally(() => state.OnLoad = false);
    },
    GetAddresBook({ state, commit }, params: any) {
        state.OnLoadAddress = true;
        return new Promise((success, error) => {
            if (localStorage.getItem('token')) {
                profileService.GetAddresBook(params).then((response) => {
                    commit("set-address-book", response.data.addresses);
                    success(response);
                }).catch(error);
            } else error(new Error("token not found!"));
        }).finally(() => state.OnLoadAddress = false);
    },
    AddAddresBook({ state, commit }, params: FormData) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            profileService.AddAddresBook(params).then((response) => {
                success(response);
            }).catch(error);
        }).finally(() => state.OnLoad = false);
    },
    UpdateAddresBook({ state, commit }, params: FormData) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            profileService.UpdateAddresBook(params).then((response) => {
                success(response);
            }).catch(error);
        }).finally(() => state.OnLoad = false);
    },
    DeleteAddresBook({ state, commit }, params: FormData) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            profileService.DeleteAddresBook(params).then((response) => {
                success(response);
            }).catch(error);
        }).finally(() => state.OnLoad = false);
    },
    GetBackAccount({ state, commit }, params: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            if (localStorage.getItem('token')) {
                profileService.GetBackAccount(params).then((response) => {
                    commit("set-address-book", response.data.items);
                    success(response);
                }).catch(error);
            } else error(new Error("token not found!"));
        }).finally(() => state.OnLoad = false);
    },
    forgotPassword({ state, commit }, params: FormData) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            authenticationService.forgotPassword(params).then((response) => {
                if (response.code >= 400) {
                    Toaster.Warning(response.msg);
                    error(new Error(response.msg));
                } else {
                    Toaster.Make(response.msg, { variant: 'success' });
                    success(response);
                }
            }).catch(error);
        }).finally(() => state.OnLoad = false);
    },
    UpdateProfile({ state, commit }, params: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            profileService.UpdateProfile(params ?? state.user).then((response) => {
                if (response.code >= 400) {
                    Toaster.Warning(response.msg);
                    error(new Error(response.msg));
                } else {
                    Toaster.Make(response.messages, { variant: 'success' });
                    success(response);
                }
            }).catch(error);
        }).finally(() => state.OnLoad = false);
    },
    GetProfile({ state, commit }, params: any) {
        state.OnLoad = true;
        return new Promise((success, error) => {
            profileService.GetProfile().then((response) => {
                if (response.code >= 400) {
                    Toaster.Warning(response.msg);
                    error(new Error(response.msg));
                } else if (response?.data?.user) {
                    commit("set-user", response.data.user);
                    success(response);
                }
            }).catch(error);
        }).finally(() => state.OnLoad = false);
    }
};
export default actions;
