import { Toaster } from "@/constant/Toaster";
import main from "@/main";
import productService from "@/services/product.service";
import rootService from "@/services/root.service";
import { ActionTree } from "vuex";
import { RootStateModel } from "../types";
import { ProductStateModel } from "./types";

const actions: ActionTree<ProductStateModel, RootStateModel> = {
    GetProvider({ state, rootState, commit }, payload: any) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            productService.GetProvider()
                .then((response) => commit("set-providers", response.data.listdata))
                .then(success)
                .catch(error)
                .finally(() => rootState.OnLoad = false);
        });
    },
    GetPaymentOption({ rootState, commit }) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            productService.GetPaymentOption().then((response) => {
                if (response?.data?.login_status === null) {
                    localStorage.setItem("redirect", JSON.stringify({ name: main.$route.name, query: main.$route.query }))
                    main.$cookies.remove("SES")
                    main.$cookies.remove("PHPSESSID")
                    Toaster.Warning("Sesi anda sudah habis. Mohon login ulang")
                    main.$router.push({name: 'login'})
                    error(response)
                } else {
                    commit("set-payment-option", response.data.listdata)
                    success(response)
                }
            }).catch(error).finally(() => rootState.OnLoad = false);
        });
    },
    BuyProduct({ rootState, commit }, params: {trx_id: string, form: FormData}) {
        rootState.OnLoad = true;
        return new Promise((success, error) => {
            productService.Buy(params.trx_id, params.form)
                .then((response) => {
                    Toaster.Make(response.messages, {title: response.status.is_valid?"Sukses":"Gagal"})
                    success(response);
                })
                .catch((err) => {
                    localStorage.setItem("redirect", JSON.stringify({ name: main.$route.name, query: main.$route.query }))
                    main.$cookies.remove("SES")
                    main.$cookies.remove("PHPSESSID")
                    Toaster.Warning("Sesi anda sudah habis. Mohon login ulang")
                    main.$router.push({name: 'login'})
                    error(err)
                })
                .finally(() => rootState.OnLoad = false);
        });
    }
};

export default actions;
