import { BaseModel } from "@/constant/BaseModel";

export interface LocationStateInterface {
    country: CountryInterface[]
    province: ProvinceInterface[]
    city: CityInterface[]
}

export class LocationStateModel extends BaseModel implements LocationStateInterface {
    country: CountryInterface[] = []
    province: ProvinceInterface[] = []
    city: CityInterface[] = []
}

export interface CountryInterface {
    country_id: string;
    country_code: string;
    country_name: string;
    country_fullname: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export interface ProvinceInterface {
    state_id: string;
    country_id: string;
    state_name: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export interface CityInterface {
    city_id: string;
    state_id: string;
    home_zone_id: string;
    city_name: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export class CountryModel extends BaseModel implements CountryInterface {
    country_id: string = '';
    country_code: string = '';
    country_name: string = '';
    country_fullname: string = '';
    status: string = '';
    is_deleted: string = '';
    created_by: string | null = null;
    created_date: string = '';
    updated_by: string | null = null;
    updated_date: string = '';
}

export class ProvinceModel extends BaseModel implements ProvinceInterface {
    state_id: string = '';
    country_id: string = '';
    state_name: string = '';
    status: string = '';
    is_deleted: string = '';
    created_by: string | null = null;
    created_date: string = '';
    updated_by: string | null = null;
    updated_date: string = '';
}

export class CityModel extends BaseModel implements CityInterface {
    city_id: string = '';
    state_id: string = '';
    home_zone_id: string = '';
    city_name: string = '';
    status: string = '';
    is_deleted: string = '';
    created_by: string | null = null;
    created_date: string = '';
    updated_by: string | null = null;
    updated_date: string = '';
}
