import axios, { Axios, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { Toaster } from "@/constant/Toaster";
import { ServerResponse } from "./response.types";
import { StatusCode } from "@/constant/Enums";
import app from "@/main";

export interface ServiceBaseOptions {
    api?: string;
    hideError?: boolean;
    useService?: boolean;
    validate_status_code?: boolean;
    with_credential?: boolean;
}

export default class BaseService {

    protected baseAPI = process.env.VUE_APP_API_URL;
    protected baseService = process.env.VUE_APP_SERVICE_URL;

    private instance!: AxiosInstance;
    private validate_status_code = false;
    private hideError = false;
    private withCredentials = false;

    private init(): AxiosInstance {
        const instance = axios.create({
            baseURL: this.baseAPI,
        });

        instance.interceptors.request.use((v) => this.requestHandler(v, this));
        instance.interceptors.response.use((...args) => this.responseHandler(args[0], args, this), this.errorResponseHandler);
        this.instance = instance;
        return instance;
    }


    public get api(): string {
        return this.baseAPI;
    }


    public set api(v: string) {
        this.baseAPI = v;
    }

    /**
     * Prepare
     */
    public Prepare(option?: ServiceBaseOptions): BaseService {
        if (option) {
            if (option?.api) this.baseAPI = option?.api;
            if (option.useService) this.baseAPI = `${option?.api ?? this.baseService}/api/v1`;
            if (option.hideError) this.hideError = option.hideError;
            if (option.validate_status_code) this.validate_status_code = option.validate_status_code;
        }

        return this;
    }


    /**
     * Base
    */
    public Base(option?: ServiceBaseOptions): AxiosInstance {
        this.withCredentials = true;
        return this.Prepare(option).init();
    }

    /**
     * BaseAPI
     */
    public BaseAPI(option?: ServiceBaseOptions): AxiosInstance {
        return this.Base(option);
    }

    /**
     * BaseService
     */
    public BaseService(option: ServiceBaseOptions = {}): AxiosInstance {
        option = { ...option, ...{ useService: true } };
        return this.Prepare(option).init();
    }

    private requestHandler(config: AxiosRequestConfig<any>, self: BaseService) {
        const headers: AxiosRequestHeaders = {};

        headers['Accept-Language'] = "id-ID";

        headers['Access-Control-Allow-Origin'] = location.origin;
        headers['access-control-allow-headers'] = "access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,authorization,x-api-key,content-type";

        headers['Access-Control-Allow-Methods'] = "GET, POST, PUT, PATCH, DELETE, OPTIONS";
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';

        const token = localStorage.getItem("token");
        if (token) headers["X-API-KEY"] = `${token}`;

        const session = app.$cookies.get("SES");
        if (session) headers["Authorization"] = `${session}`;

        config.headers.common = 'XMLHttpRequest';
        config.headers = headers;
        // config.timeout = 10000;

        if (self.withCredentials && process.env.NODE_ENV === "production") config.withCredentials = true;

        return config;
    }

    private responseHandler(response: AxiosResponse<ServerResponse>, allargs: any, self: BaseService) {

        let body = response.data;
        if (response.headers['content-type'].indexOf('application/json') >= 0) {
            if (this.validate_status_code) {
                if (response.status >= 200 && response.status <= 202) {
                    return body;
                } else {
                    Toaster.Error(response.statusText, { title: "Respon Server Tidak Valid!", autoHideDelay: 10000 });
                    return new Error(response.statusText);
                }
            } else if ((body?.status as any) === true) {
                if (body.status) return body;
                else throw new Error(body.remarks);
            } else if (typeof body === 'string' && (body as string).indexOf('</div>{') >= 0) {
                let json = (new RegExp(/(\{.*\}$)/g)).exec(body);
                body = JSON.parse(json[0]);
            } else if ((typeof body?.status === 'object') && self.hideError === false) {
                if (body?.status?.code >= StatusCode.InternalServerError) Toaster.Error(body?.messages ?? "");
                else if (body?.status?.code >= StatusCode.BadRequest) Toaster.Warning(body?.messages ?? "");
                else if (body?.status?.code >= StatusCode.MultipleChoices) Toaster.Make(body?.messages ?? "", { title: "Info", variant: 'info' });
                // else if (body?.status?.code >= StatusCode.Ok) Toaster.Make(body?.messages ?? "", { title: 'OK', variant: 'success' });
                // else if (body?.status?.code >= StatusCode.Continue) Toaster.Make(body?.messages ?? "", { title: 'OK', variant: 'secondary' });
                // else Toaster.Make(body?.messages, { title: 'INFO', variant: 'info' });

                return body;
            } return body;
        } else {
            let message = "Gagal menterjemahkan data. Mohon hubungi admin!";
            if (!this.hideError) Toaster.Error(message, { title: "Respon Server Tidak Valid!", autoHideDelay: 10000 });
            return new Error(message);
        }

    }

    private errorResponseHandler(error: any) {
        const response = error.response as AxiosResponse;
        if (response?.data) Toaster.Warning(response?.data?.message);
        else if (JSON.stringify(error).indexOf("timeout") < 0) Toaster.Error(error);
        return error;
    }
}
