import rootService from "@/services/root.service";
import { ActionTree } from "vuex";
import { RootStateType } from "../types";
import { PartnerStateInterface } from "./types";

const actions: ActionTree<PartnerStateInterface, RootStateType> = {
    GetPartner({ state, rootState, commit }, payload: any) {
        rootState.OnLoad = true
        return new Promise((success, error) => {
            rootService.GetPartner()
                .then((response) => commit("set-partner-providers", response.data))
                .then(success)
                .catch(error)
                .finally(() => rootState.OnLoad = false)
        })
    }
}

export default actions
