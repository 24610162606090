import "@/constant/Extensions";
import Vue from "vue";
import App from "@/App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from './i18n';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import '@/assets/scss/main.scss';
import VueCookies from 'vue-cookies';

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies);

/** @documentation see [Cleave.js](https://nosir.github.io/cleave.js) */
const Cleave = require("cleave.js");

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.component("b-currency", () => import("@/components/input/Currency.vue"));
Vue.component("b-step", () => import("@/components/input/Step.vue"));
Vue.component("not-found", () => import("@/components/NotFoundBanner.vue"));
Vue.component("user-address", () => import("@/components/input/AddressSelector.vue"));

Vue.config.productionTip = false;

/**
 * @documentation https://v2.vuejs.org/v2/guide/custom-directive.html?redirect=true#Directive-Hook-Arguments
*/
Vue.directive("mask", {
  bind: function (el, binding, VNode, oldVnode) {
    if (binding.modifiers.currency) {
      new Cleave.default(el, {
        numeral: true
      });
    }

  }
});

Vue.directive("error", {
  bind: function (el, binding, VNode, oldVnode) {
    if (binding.modifiers.img) {
      el.addEventListener('error', (ev: Event | any) => {
        let color = (binding?.value?.color ?? (binding.modifiers.color ? binding.value : "f3f3f3")).replace('#', '');
        let text = binding?.value?.text ?? (binding.modifiers.text ? binding.value : "tidak ditemukan");
        let size = binding?.value?.size ?? (binding.modifiers.size ? binding.value : "300x200");
        let alternative = binding?.value?.alt ?? `https://via.placeholder.com/${size}/${color}?text=${text}`;
        if(typeof binding?.value === 'function') alternative = binding?.value()
        ev.target.src = alternative;
      });
    }

  }
});

const main = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export default main;
