import { TestimonialModel } from "@/store/types";
import { MutationTree } from "vuex";
import { AdditionalCoverInterface, AdditionalCoverModel } from "../motor/types";
import { ClauseDataSummaryModel, ConfirmationUserModel, ConfirmationVehicleModel, PlateInterface, PlateModel, ProductAdditionalModel, ProductBrandInterface, ProductBrandModel, ProductDataInterface, ProductFeatureModel, ProductModel, ProductSummaryModel, ProductTestimonyInterface, RatingDetailModel, RatingModel } from "../types";
import { CompareContentInterface, PaymentProductMobilConfirmationInterface, ProductMobilState } from "./types";

const mutations: MutationTree<ProductMobilState> = {
    "set-plates": (state, plates: PlateInterface[]) => {
        if (plates) {
            state.plates = []
            plates.forEach((v)=> state.plates.push((new PlateModel).SetRawAttributes(v)))
        }
    },
    "set-page-content": (state, data: any) => {
        state.title = data.page_title
        state.content = data.page_content
        state.banner = data.main_banner
    },
    "set-list-data": (state, data: ProductDataInterface) => {
        state.product.total = data.total
        state.product.listdata = data.listdata.map((item) => new ProductModel(item))
    },
    "set-list-compare": (state, contents: CompareContentInterface[]) => {
        state.listCompare = contents?.map((item) => {
            let product = (new ProductModel).SetRawAttributes(item.product)
            product.feature = product.feature?.map((feature) => (new ProductFeatureModel).SetRawAttributes(feature))??[]
            product.additional = product.additional?.map((additional) => (new ProductAdditionalModel).SetRawAttributes(additional))??[]
            return {product}
        })
    },
    "set-summary": (state, summary) => {
        if (summary) {
            state.summary = (new ProductSummaryModel).SetRawAttributes(summary)
            state.summary.clause.listdata = state.summary?.clause?.listdata?.map((item) => (new ClauseDataSummaryModel).SetRawAttributes(item))
            // state.summary.feature.listdata = state.summary.feature.listdata.map((item) => (new ClauseDataSummaryModel).SetRawAttributes(item))
        }
    },
    "set-brands": (state, brands: ProductBrandInterface[]) => {
        state.brands = brands.map((item) => (new ProductBrandModel).SetRawAttributes(item))
    },
    "set-product-testimony": (state, testimony: ProductTestimonyInterface) => {
        state.testimony.avg_rating = testimony?.avg_rating??0
        state.testimony.sum_rating = testimony?.sum_rating??0
        state.testimony.rating = testimony?.rating?.map((item) => {
            let rating = (new RatingModel).SetRawAttributes(item)
            rating.data = (new RatingDetailModel).SetRawAttributes(item.data)
            return rating
        })?.sort((a, b) => a.rating - b.rating)??[]

        state.testimony.list_testimonial = testimony?.list_testimonial?.map((item) => {
            return (new TestimonialModel).SetRawAttributes(item)
        })??[]
    },
    "set-payment-confirmation": (state, confirmation: PaymentProductMobilConfirmationInterface) => {
        state.confirmation.product = (new ProductModel).SetRawAttributes(confirmation.product)
        state.confirmation.user = (new ConfirmationUserModel).SetRawAttributes(confirmation.user)
        state.confirmation.vehicle = (new ConfirmationVehicleModel).SetRawAttributes(confirmation.vehicle)
        state.confirmation.promo_code = confirmation.promo_code
    },
    "set-additional-cover": (state, additional: AdditionalCoverInterface[]) => {
        if (additional) {
            state.additional_cover = [];
            additional.forEach((item) =>  state.additional_cover.push((new AdditionalCoverModel).SetRawAttributes(item)))
        }
    },
}

export default mutations
