import { FormComparePropertyInterface } from "@/store/product/properti/types";
import BaseService, { ServiceBaseOptions } from ".";
import { ServerResponse } from "./response.types";

class ProductPropertyService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}/asuransi-rumah`;
    }

    /**
     * GetProvider
    */
    public GetProperty(): Promise<ServerResponse> {
        let self = new ProductPropertyService
        return self.Base().get('/');
    }

    /**
     * GetData
     */
    public GetData(params: FormComparePropertyInterface): Promise<ServerResponse> {
        let self = new ProductPropertyService
        let form = new FormData
        params.suminsured = params.suminsured.replace(new RegExp(/[^\d]/,'g'), '')
        Object.keys(params).forEach((key) => form.append(key, params[key]))
        return self.Base().post('get_data', form);
    }

    /**
     * GetSummary
    */
     public GetSummary(quotation_id: string, params?: any): Promise<ServerResponse> {
        let self = new ProductPropertyService;
        return self.Base().get(`summary/${quotation_id}`, { params });
    }

    /**
     * Compare
     */
    public Compare(quotation_ids: FormData) {
        let self = new ProductPropertyService;
        return this.Base().post(`compare`, quotation_ids)
    }

    /**
     * GetUlasan
     */
     public GetUlasan(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductPropertyService;
        return self.Base({ api: process.env.VUE_APP_API_URL, hideError: true }).get(`home/testimony/${quotation_id ?? 'empty'}`);
     }

    /**
     * AddDetailProduct
    */
    public AddDetailProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductPropertyService;
        return self.Base().post(`add_detail_produk/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public AddPersonalProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductPropertyService;
        return self.Base().post(`add_personal_detail/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmProduct(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductPropertyService;
        return self.Base().get(`confirm/${quotation_id}`);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmCheckout(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductPropertyService;
        return self.Base().get(`checkout/${quotation_id}`);
    }

    /**
     * UseVoucher
    */
    public UseVoucher(quotation_id: string, params: FormData): Promise<ServerResponse> {
        let self = new ProductPropertyService({
            hideError: true,
            validate_status_code: false
        });
        return self.Base().post(`voucher/${quotation_id}`, params);
    }

}

export default new ProductPropertyService;
