import { MutationTree } from "vuex";
import { CityModel, CountryInterface, CountryModel, LocationStateInterface, ProvinceInterface, ProvinceModel } from "./types";

const mutations: MutationTree<LocationStateInterface> = {
    "set-country": (state, country: CountryInterface[]) => {
        state.country = country.map((item) => (new CountryModel).SetRawAttributes(item))
    },
    "set-province": (state, province: ProvinceInterface[]) => {
        state.province = province.map((item) => (new ProvinceModel).SetRawAttributes(item))
    },
    "set-city": (state, city: CountryInterface[]) => {
        state.city = city.map((item) => (new CityModel).SetRawAttributes(item))
    },
}

export default mutations
