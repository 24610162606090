import app from "@/main";
import { BvToastOptions } from "bootstrap-vue";
import { AxiosError } from "axios";
export class Toaster {
    public static Make(message: string, options: BvToastOptions) {
        const config: BvToastOptions = {
            title: "OK",
            toaster: 'b-toaster-bottom-right'
        };
        return app.$bvToast.toast(message, { ...config, ...options });
    }

    /**
     * Error
    */
    public static Error(error: any, options?: BvToastOptions) {
        const config: BvToastOptions = {
            title: "Error",
            variant: 'danger'
        };

        if (error instanceof Error) error = error.message;

        return this.Make(error, { ...config, ...options });
    }

    /**
     * Warning
    */
    public static Warning(error: any, options?: BvToastOptions) {
        const config: BvToastOptions = {
            title: "Warning",
            variant: 'warning'
        };

        if (error instanceof Error) error = error.message;

        return this.Make(error, { ...config, ...options });
    }
}
