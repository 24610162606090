import { Module } from "vuex";
import { RootStateType } from "../types";
import { TransactionStateModel } from "./types";


const state = new TransactionStateModel
import actions from "./actions";
import mutations from "./mutations";

const transaction: Module<TransactionStateModel, RootStateType> = {
    namespaced: true,
    state,
    actions,
    mutations
}


export default transaction
