declare global {
    interface String {
        HTMLtoString(this: string): string;
        ResolvePath(this: string): string
    }
}

String.prototype.HTMLtoString = function (): string {
    let span = document.createElement('span');
    span.innerHTML = this;
    span.innerHTML=span.innerHTML.replace(/<br>/gi, "\n");
    span.innerHTML=span.innerHTML.replace(/<p.*>/gi, "\n")
    return span.textContent ?? span.innerText;
};

export { }
