import Vue from "vue";
import Vuex from "vuex";
import { RootStateModel } from "./types";

Vue.use(Vuex);

const state = new RootStateModel
import mutations from "./mutations";
import actions from "./actions";

import product from "./product";
import auth from "./auth";
import partner from "./partner";
import location from "./location";
import transaction from "./transaction";

export default new Vuex.Store({
  modules: {
    product,
    auth,
    partner,
    location,
    transaction
  },
  state,
  mutations,
  actions,
});
