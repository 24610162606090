import main from "@/main";
const id = require("@/locales/id.json");

export enum LAYOUTS {
    GENERAL = 'general',
    AUTHENTICATION = 'authentication',
    PROFILE = 'profile',
    COMPARE = 'compare'
}

export enum LOCALES {
    EN = "en",
    ID = "id"
}

export const PRODUCTS = [
    {
        name: id["product-motor"] as string,
        image: "img/products/motorcycle.png",
        image_hover: "img/products/hover-motorcycle.png"
    },
    {
        name: id["product-mobil"] as string,
        image: "img/products/car.png",
        image_hover: "img/products/hover-car.png"
    },
    {
        name: id["product-properti"] as string,
        image: "img/products/truck.png",
        image_hover: "img/products/hover-truck.png"
    },
    {
        name: id["product-kecelakaan-diri"] as string,
        image: "img/products/ambulance.png",
        image_hover: "img/products/hover-ambulance.png"
    },
    // {
    //     name: id["product-jiwa"] as string,
    //     image: "img/products/house.png",
    //_hover     image: "img/products/hover-house.png"
    // },
    {
        name: id["product-kesehatan"] as string,
        image: "img/products/medical.png",
        image_hover: "img/products/hover-medical.png"
    },
    // {
    //     name: id["product-haji"] as string,
    //     image: "img/products/hospitality.png",
    //_hover     image: "img/products/hover-hospitality.png"
    // },
];

export const INSURANCES = [
    {
        text: "TLO",
        value: 1
    },
    {
        text: "All Risk",
        value: 2
    }
];

export const PROPERTY_COVERAGE = [
    {text : 'Earthquake', value: 1},
    {text : 'Not Earthquake', value: 2}
]

export const HEALTY_TYPES = [
    {
        text: "Hemat",
        value: 1,
    },
    {
        text: "Reguler",
        value: 2,
    }
];

export const HEALTY_PERSONAL_TYPES = [
    {
        value: "0",
        text: "Semua Paket"
    },
    {
        value: "16",
        text: "Rp 50.000 - 100.000 Paket"
    },
    {
        value: "17",
        text: "Rp 100.000 - 500.000 Paket"
    },
    {
        value: "18",
        text: "&gt;Rp 500.000 Paket"
    },
    {
        value: "19",
        text: "Rp. 18.000,- Paket"
    },
    {
        value: "20",
        text: "Rp. 35.500,- Paket"
    },
    {
        value: "21",
        text: "Rp. 177.500,- Paket"
    },
    {
        value: "22",
        text: "Rp. 355.000,- Paket"
    },
    {
        value: "23",
        text: "Rp. 532.000,- Paket"
    },
    {
        value: "24",
        text: "Rp. 709.500,- Paket"
    },
    {
        value: "25",
        text: "Rp. 886.500,- Paket"
    }
    ,
];


export const LIFE_INSURANCE_PROTECTIONS = [
    {
        value: "0",
        text: "Semua Paket"
    },
    {
        value: "16",
        text: "Rp 50.000 - 100.000 Paket"
    },
    {
        value: "17",
        text: "Rp 100.000 - 500.000 Paket"
    },
    {
        value: "18",
        text: "&gt;Rp 500.000 Paket"
    },
    {
        value: "19",
        text: "Rp. 18.000,- Paket"
    },
    {
        value: "20",
        text: "Rp. 35.500,- Paket"
    },
    {
        value: "21",
        text: "Rp. 177.500,- Paket"
    },
    {
        value: "22",
        text: "Rp. 355.000,- Paket"
    },
    {
        value: "23",
        text: "Rp. 532.000,- Paket"
    },
    {
        value: "24",
        text: "Rp. 709.500,- Paket"
    },
    {
        value: "25",
        text: "Rp. 886.500,- Paket"
    },
];

export const GENDER = [
    {
        text: "Laki-laki",
        value: 1
    },
    {
        text: "Perempuan",
        value: 2
    }
];

// export const HEALT_PROTECTION = [
//     {
//         value: "1",
//         text: "Kesehatan"
//     },
//     {
//         value: "2",
//         text: "Unit Link (Jiwa + Investasi)"
//     },
//     {
//         value: "3",
//         text: "Kecelakaan Diri"
//     },
// ];

export enum StatusCode {
    Continue = 100,
    SwitchingProtocols = 101,
    Processing = 102,
    EarlyHints = 103,
    Ok = 200,
    Created = 201,
    Accepted = 202,
    NonAuthoritativeInformation = 203,
    NoContent = 204,
    ResetContent = 205,
    PartialContent = 206,
    MultiStatus = 207,
    AlreadyReported = 208,
    ImUsed = 226,
    MultipleChoices = 300,
    MovedPermanently = 301,
    Found = 302,
    SeeOther = 303,
    NotModified = 304,
    UseProxy = 305,
    Unused = 306,
    TemporaryRedirect = 307,
    PermanentRedirect = 308,
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthenticationRequired = 407,
    RequestTimeout = 408,
    Conflict = 409,
    Gone = 410,
    LengthRequired = 411,
    PreconditionFailed = 412,
    PayloadTooLarge = 413,
    UriTooLong = 414,
    UnsupportedMediaType = 415,
    RangeNotSatisfiable = 416,
    ExpectationFailed = 417,
    ImATeapot = 418,
    MisdirectedRequest = 421,
    UnprocessableEntity = 422,
    Locked = 423,
    FailedDependency = 424,
    TooEarly = 425,
    UpgradeRequired = 426,
    PreconditionRequired = 428,
    TooManyRequests = 429,
    RequestHeaderFieldsTooLarge = 431,
    UnavailableForLegalReasons = 451,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    HttpVersionNotSupported = 505,
    VariantAlsoNegotiates = 506,
    InsufficientStorage = 507,
    LoopDetected = 508,
    NotExtended = 510,
    NetworkAuthenticationRequired = 511,
}

export enum EVENT_PROMO_FLAG {
    EVENT = "event",
    NEWS = "news",
    PROMO = "promo",
    NEWPROMO = "newss promo"
}

export enum INSURANCE_TYPE {
    CAR_INSURANCE = 1,
    MOTORCYCLE_INSURANCE = 2,
    PERSONAL_ACCIDENT = 3,
    HOME_INSURANCE = 4,
    LIFE_INSURANCE = 5,
    TRAVEL_INSURANCE = 6,
    HEALTH_INSURANCE = 7,
    UMRAH_INSURANCE = 8,
}

export enum MOTOR_COVERAGE {
    ALL_RISK = 1,
    TLO = 2
}

export const PRODUCT_INSTALLMENT_MONTH_DIFF = [3, 6, 12]


export enum PAGE_CONTENT_TYPE {
    DASHBOARD = 0,
    ABOUT_US = 1,
    PRODUCT = 2,
    REKAN = 3,
    BANTUAN = 4,
    FAQ = 5,
    LOGIN_REGISTER = 6,
    XXX = 7,
    HERO = 8,
    MEDIA = 9,
    BANTUAN_STEP = 10,
    SLIDER = 11
}

export const TRANSACTION_STATUS = {
    0:"Pending",
    1:"Follow Up",
    2:"Waiting for Payment",
    3:"Paid",
    4:"Cancel"
}

export const RAJA_PREMI_INFO = {
    phone: {
        icon: "rp-phone",
        title: "(021) 5790 494"
    },
    whatsapp: {
        icon: "rp-whatsapp",
        title: "(+62) 0852 9000 3471"
    },
    email: {
        icon: "rp-email",
        // title: "Info@ajapremi.com.id"
        title: "info@rajapremi.co.id"
    },
    // location: {
    //     icon: "rp-location",
    //     title: "BStartup Center, Menara Batavia lt 3A Jl. KH Mas Mansyur Kav 126 Jakarta Pusat 10220"
    // },
    workhour: {
        icon: "rp-clock",
        title: "Senin - Jumat, 8am - 6pm"
    },
};
