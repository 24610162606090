import { BaseModel } from "@/constant/BaseModel";

export interface PartnerStateInterface {
    site_lang: string
    page_title: string
    main_banner: string
    providers: PartnerProviderInterface[]

}

export class PartnerStateModel extends BaseModel implements PartnerStateInterface {
    site_lang: string = ""
    page_title: string = ""
    main_banner: string = ""
    providers: PartnerProviderInterface[] = []
}

export interface PartnerProviderInterface {
    provider_id: string
    provider_name: string
    alias_name: string
    address: string
    email: string
    phone: string
    fax: string
    logo: string
    detail_ind: string
    detail_eng: string
    detail_ind_trunc?: string
    detail_eng_trunc?: string
    website: string
    subdomain: string
    show_provider: string
    seo_link: string
    seo_key: string|null
    seo_desc: string|null
    seo_title: string|null
    status: string
    is_deleted: string
    created_by: string
    created_date: string
    updated_by: string|null
    updated_date: string|null
    old_provider_id: string
}

export class PartnerProviderModel extends BaseModel implements PartnerProviderInterface {
    provider_id: string = ""
    provider_name: string = ""
    alias_name: string = ""
    address: string = ""
    email: string = ""
    phone: string = ""
    fax: string = ""
    logo: string = ""
    detail_ind: string = ""
    detail_eng: string = ""
    detail_ind_trunc?: string = ""
    detail_eng_trunc?: string = ""
    website: string = ""
    subdomain: string = ""
    show_provider: string = ""
    seo_link: string = ""
    seo_key: string|null = null
    seo_desc: string|null = null
    seo_title: string|null = null
    status: string = ""
    is_deleted: string = ""
    created_by: string = ""
    created_date: string = ""
    updated_by: string|null = null
    updated_date: string|null = null
    old_provider_id: string = ""
}
