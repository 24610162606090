import { BaseModel } from "@/constant/BaseModel";
import { InsuranceTypeModel, ProductModel, ProviderModel } from "../product/types";

export interface TransactionStateInterface {
    OnLoad: boolean;
    history: TransactionHistoryModel;
    detail_hisory: DetailTransactionModel;
}

export class TransactionStateModel extends BaseModel implements TransactionStateInterface {
    OnLoad: boolean = false;
    history: TransactionHistoryModel = new TransactionHistoryModel;
    detail_hisory: DetailTransactionModel = new DetailTransactionModel;
}

export interface TransactionHistoryInterface {
    total: number;
    listdata: TransactionModel[];
}

export class TransactionHistoryModel extends BaseModel implements TransactionHistoryInterface {
    total: number = 0;
    listdata: TransactionModel[] = [];
}

export interface TransactionInterface {
    transaction_id: string;
    insurance_type_id: string;
    transaction_type: string;
    inquiry_id: string | null;
    product_id: string;
    coverage_type: string;
    user_id: string | null;
    trx_id: string;
    surcharge: string;
    handling_fee: string;
    total: string;
    paid: string | null;
    remark: string | null;
    sum_insured: string | null;
    basic_commission_rate: string;
    basic_commision_amount: string;
    add_commision_rate: string;
    add_commision_amount: string;
    tax_rate: string;
    tax_amount: string;
    rate_basic: string;
    gross_premium: string;
    discount_rate: string;
    discount_amount: string;
    additional_discount_rate: string;
    additional_discount_amount: string;
    additional_discount_code: string;
    affiliate_code: string;
    affiliate_amount: string;
    nett_premium: string;
    additional_premium: string;
    agent_id: string;
    agent_commission: string;
    representative_id: string | null;
    policy_number: string | null;
    shipper: string | null;
    shipping_id: string | null;
    e_policy: string | null;
    recipient: string | null;
    shipping_date: string | null;
    fullfilment_date: string | null;
    paid_date: string | null;
    policy_active_date: string | null;
    policy_expired_date: string | null;
    periode_policy: string;
    is_online: string;
    source: string;
    platform: string;
    browser: string;
    payment_channel: string | null;
    collection_amount: string;
    status: string;
    substatus: string | null;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
    transaction_profile_id: string;
    first_name: string;
    last_name: string;
    idcard: string;
    gender: string | null;
    born_date: string;
    occupation: string;
    email: string;
    phone: string;
    address: string;
    shipping_reciever: string;
    shipping_phone: string;
    shipping_address: string;
    city_id: string;
    state_id: string;
    country_id: string;
    zipcode: string;
    beneficiary_id: string | null;
    beneficiary_name: string | null;
    sendinblue_sync: string | null;
    dtm: string;
    status_transaction: string;
}

export class TransactionModel extends BaseModel implements TransactionInterface {
    transaction_id: string = "";
    insurance_type_id: string = "";
    transaction_type: string = "";
    inquiry_id: string | null = null;
    product_id: string = "";
    coverage_type: string = "";
    user_id: string | null = null;
    trx_id: string = "";
    surcharge: string = "";
    handling_fee: string = "";
    total: string = "";
    paid: string | null = null;
    remark: string | null = null;
    sum_insured: string | null = null;
    basic_commission_rate: string = "";
    basic_commision_amount: string = "";
    add_commision_rate: string = "";
    add_commision_amount: string = "";
    tax_rate: string = "";
    tax_amount: string = "";
    rate_basic: string = "";
    gross_premium: string = "";
    discount_rate: string = "";
    discount_amount: string = "";
    additional_discount_rate: string = "";
    additional_discount_amount: string = "";
    additional_discount_code: string = "";
    affiliate_code: string = "";
    affiliate_amount: string = "";
    nett_premium: string = "";
    additional_premium: string = "";
    agent_id: string = "";
    agent_commission: string = "";
    representative_id: string | null = null;
    policy_number: string | null = null;
    shipper: string | null = null;
    shipping_id: string | null = null;
    e_policy: string | null = null;
    recipient: string | null = null;
    shipping_date: string | null = null;
    fullfilment_date: string | null = null;
    paid_date: string | null = null;
    policy_active_date: string | null = null;
    policy_expired_date: string | null = null;
    periode_policy: string = "";
    is_online: string = "";
    source: string = "";
    platform: string = "";
    browser: string = "";
    payment_channel: string | null = null;
    collection_amount: string = "";
    status: string = "";
    substatus: string | null = null;
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
    transaction_profile_id: string = "";
    first_name: string = "";
    last_name: string = "";
    idcard: string = "";
    gender: string | null = null;
    born_date: string = "";
    occupation: string = "";
    email: string = "";
    phone: string = "";
    address: string = "";
    shipping_reciever: string = "";
    shipping_phone: string = "";
    shipping_address: string = "";
    city_id: string = "";
    state_id: string = "";
    country_id: string = "";
    zipcode: string = "";
    beneficiary_id: string | null = null;
    beneficiary_name: string | null = null;
    sendinblue_sync: string | null = null;
    dtm: string = "";
    status_transaction: string = "";
}

export interface ProfileTransactionInterface {
    transaction_profile_id: string;
    transaction_id: string;
    first_name: string;
    last_name: string;
    idcard: string;
    gender: null,
    born_date: string;
    occupation: string;
    email: string;
    phone: string;
    address: string;
    shipping_reciever: string;
    shipping_phone: string;
    shipping_address: string;
    city_id: string;
    state_id: string;
    country_id: string;
    zipcode: string;
    beneficiary_id: string | null;
    beneficiary_name: string | null;
    sendinblue_sync: string | null;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
    dtm: string;
}

export class ProfileTransactionModel extends BaseModel implements ProfileTransactionInterface {
    transaction_profile_id: string = "";
    transaction_id: string = "";
    first_name: string = "";
    last_name: string = "";
    idcard: string = "";
    gender: null = null;
    born_date: string = "";
    occupation: string = "";
    email: string = "";
    phone: string = "";
    address: string = "";
    shipping_reciever: string = "";
    shipping_phone: string = "";
    shipping_address: string = "";
    city_id: string = "";
    state_id: string = "";
    country_id: string = "";
    zipcode: string = "";
    beneficiary_id: string | null = null;
    beneficiary_name: string | null = null;
    sendinblue_sync: string | null = null;
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
    dtm: string = "";

    public get full_name(): string {
        return `${this.first_name} ${this.last_name}`;
    }

}

export interface DetailHistoryInterface {
    profile: ProfileTransactionModel;
    product: ProductModel,
    provider: ProviderModel;
    insurance_type: InsuranceTypeModel;
    page_title: string;
    site_lang: string;
    verify: boolean;
    transaction: TransactionModel;
    log: TransactionLogModel[]
}

export interface TransactionLogInterface {
    log_id: string;
    description: string;
}

export class TransactionLogModel extends BaseModel implements TransactionLogInterface {
    log_id: string = "";
    description: string = "";
}

export class DetailTransactionModel extends BaseModel implements DetailHistoryInterface {
    profile: ProfileTransactionModel = new ProfileTransactionModel;
    product: ProductModel = new ProductModel;
    provider: ProviderModel = new ProviderModel;
    insurance_type: InsuranceTypeModel = new InsuranceTypeModel;
    page_title: string = "";
    site_lang: string = "";
    verify: boolean = false;
    transaction: TransactionModel = new TransactionModel;
    log: TransactionLogModel[];
}
