import { BaseModel } from "@/constant/BaseModel";
import { ProvinceInterface } from "@/store/location/types";
import { CompareContentInterface } from "../mobil/types";
import { ConfirmationUserInterface, ConfirmationUserModel, ConfirmationVehicleInterface, ConfirmationVehicleModel, ProductDataInterface, ProductDataModel, ProductInterface, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, ProductTestimonyModel } from "../types";

export interface ProductPropertyStateInterface {
    onLoad: boolean;
    title: string;
    content: string;
    banner: string;
    states: ProvinceInterface[];
    listCompare: CompareContentInterface[]
}

export class ProductPropertyStateModel extends BaseModel implements ProductPropertyStateInterface {
    onLoad: boolean = false;
    title: string = "";
    content: string = "";
    banner: string = "";
    states: ProvinceInterface[] = [];
    form: FormComparePropertyInterface = new FormComparePropertyModel;
    data: ProductDataInterface = new ProductDataModel;
    summary: ProductSummaryInterface = new ProductSummaryModel;
    testimony: ProductTestimonyInterface = new ProductTestimonyModel;
    confirmation: PaymentProductPropertiConfirmationInterface = new PaymentProductPropertiConfirmationModel;
    listCompare: CompareContentInterface[] = []
}

export interface FormComparePropertyInterface {
    suminsured: string;
    state_id: string;
    city_id: string;
    // coverage: number;
    sort: string;
}

export class FormComparePropertyModel extends BaseModel implements FormComparePropertyInterface {
    suminsured: string = "";
    state_id: string = "";
    city_id: string = "";
    // coverage: number = 1;
    sort: string = "a-z";
}

export interface PaymentProductPropertiConfirmationInterface {
    product: ProductInterface;
}
export class PaymentProductPropertiConfirmationModel extends BaseModel implements PaymentProductPropertiConfirmationInterface {
    product: ProductModel = new ProductModel;
}
