import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import { ServerResponse } from "./response.types";

class ProductMotorService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}/motor`;
    }

    /**
     * GetProvider
     */
    public GetMotor(): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get('index');
    }

    /**
     * GetProvider
     */
    public GetPlates(): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get('get_plate_numbers');
    }

    /**
     * Compate
     */
    public GetData(formParams: any) {
        let self = new ProductMotorService();
        let form = new FormData;
        if (formParams instanceof FormData) {
            form = formParams;
        } else {
            for (const key in formParams) {
                if (formParams[key]) form.append(key, formParams[key]);
            }
        }
        return self.Base({hideError: true}).post('get_data', form);
    }

    /**
     * GetSummary
     */
    public Compare(params: FormData): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().post(`compare`, params);
    }

    /**
     * GetSummary
     */
    public GetSummary(quotation_id: string, params?: any): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get(`summary/${quotation_id}`, { params });
    }

    /**
     * GetAdditionalCover
     */
    public GetAdditionalCover(params?: any): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get('get_additionals', { params });
    }

    /**
     * GetUlasan
     */
    public GetUlasan(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base({hideError: true}).get(`testimony/${quotation_id ?? 'empty'}`);
    }

    /**
     * AddDetailProduct
     */
    public AddDetailProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().post(`add_detail_produk/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
*/
    public AddPersonalProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().post(`add_personal_detail/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmProduct(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get(`confirm/${quotation_id}`);
    }


    /**
     * AddDetailProduct
    */
    public ConfirmCheckout(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get(`checkout/${quotation_id}`);
    }

    /**
     * GetBrands
     */
    public GetBrands(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base().get(`get_brands/${quotation_id}`);
    }

    /**
     * UseVoucher
     */
    public UseVoucher(quotation_id: string, params: FormData): Promise<ServerResponse> {
        let self = new ProductMotorService;
        return self.Base({hideError: true}).post(`voucher/${quotation_id}`, params);
    }

}

export default new ProductMotorService;
