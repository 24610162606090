import { LAYOUTS } from "@/constant/Enums";
import { MutationTree } from "vuex";
import { ProductTestimonyInterface, RatingDetailModel, RatingModel } from "./product/types";
import { AboutModel, ConfigInterface, ConfigModel, ContentInterface, ContentModel, EventAndPromoInterface, EventAndPromoModel, FAQGroupInterface, FAQGroupModel, FAQItemsInterface, FAQItemsModel, FeatureInterface, FeatureModel, RootStateType, TestimonialModel } from "./types";

const mutations: MutationTree<RootStateType> = {
    "set-layout": (state, layout: LAYOUTS) => {
        state.CurrentLayout = layout
    },
    "set-about": (state, abouts: AboutModel[]) => {
        state.abouts = [];
        abouts.forEach((about) => state.abouts.push((new AboutModel).SetRawAttributes(about)))
    },
    "set-feature-by": (state, features: FeatureInterface[]) => {
        state.features = []
        features.forEach((feature) => state.features.push((new FeatureModel).SetRawAttributes(feature)))
    },
    "set-testimonial": (state, testimonial: TestimonialModel[]) => {
        state.testimonial = [];
        testimonial.forEach((testimoni) => state.testimonial.push((new TestimonialModel).SetRawAttributes(testimoni)))
    },
    "set-event-promo": (state, payload: EventAndPromoInterface[]) => {
        state.eventpromo = []
        payload.forEach((v)=>state.eventpromo.push((new EventAndPromoModel).SetRawAttributes(v)))
    },
    "set-config": (state, configs: ConfigInterface[]) => {
        state.configs = []
        configs.forEach((item) => state.configs.push((new ConfigModel).SetRawAttributes(item)))
    },
    "set-testimony": (state, testimony: ProductTestimonyInterface) => {
        state.testimony.avg_rating = testimony.avg_rating
        state.testimony.sum_rating = testimony.sum_rating
        state.testimony.rating = testimony.rating.map((item) => {
            let rating = (new RatingModel).SetRawAttributes(item)
            rating.data = (new RatingDetailModel).SetRawAttributes(item.data)
            return rating
        }).sort((a, b) => a.rating - b.rating)

        state.testimony.list_testimonial = testimony.list_testimonial.map((item) => {
            return (new TestimonialModel).SetRawAttributes(item)
        })
    },
    "set-page-content": (state, data: ContentInterface[]) => {
        if (data instanceof Array) {
            state.page_content = data?.map((item) => (new ContentModel).SetRawAttributes(item))
        }
    },
    "set-faq-group":(state, data: FAQGroupInterface[]) => {
        state.faq_group = data.map((item) => (new FAQGroupModel).SetRawAttributes(item))
    },
    "set-page-items":(state, data: FAQItemsInterface[]) => {
        state.faq_item = data.map((item) => (new FAQItemsModel).SetRawAttributes(item))
    },
}

export default mutations
