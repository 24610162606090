import { RootStateModel } from "@/store/types";
import { Module } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import { ProductMobilState } from "./types";

const mobil: Module<ProductMobilState, RootStateModel> = {
    namespaced: true,
    state: new ProductMobilState,
    actions,
    mutations
}

export default mobil
