import { RootStateModel } from "@/store/types";
import { Module } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import { ProductMotorState } from "./types";

const motor: Module<ProductMotorState, RootStateModel> = {
    namespaced: true,
    state: new ProductMotorState,
    actions,
    mutations
}

export default motor
