import { BaseModel } from "@/constant/BaseModel";
import { EVENT_PROMO_FLAG, LAYOUTS, LOCALES, PAGE_CONTENT_TYPE } from "@/constant/Enums";
import { AuthStateModel, AuthStateType } from "./auth/types";
import { LocationStateInterface, LocationStateModel } from "./location/types";
import { PartnerStateInterface, PartnerStateModel } from "./partner/types";
import { ProductStateModel, ProductStateType, ProductTestimonyInterface, ProductTestimonyModel } from "./product/types";
import { TransactionStateInterface, TransactionStateModel } from "./transaction/types";

export interface RootStateType {
    OnLoad: boolean;
    CurrentLayout: LAYOUTS;
    locale: LOCALES;
    abouts: AboutInterface[];
    features: FeatureInterface[];
    testimonial: TestimonialInterface[];
    testimony: ProductTestimonyInterface;
    eventpromo: EventAndPromoInterface[];
    configs: ConfigInterface[];
    page_content: ContentModel[];
    faq_group: FAQGroupModel[]
    faq_item: FAQItemsModel[]

}

export class RootStateModel extends BaseModel implements RootStateType {
    OnLoad: boolean = false;
    CurrentLayout: LAYOUTS = LAYOUTS.GENERAL;
    locale: LOCALES = LOCALES.ID;
    abouts: AboutModel[] = [];
    features: FeatureModel[] = [];
    testimonial: TestimonialModel[] = [];
    testimony: ProductTestimonyInterface = new ProductTestimonyModel;
    eventpromo: EventAndPromoInterface[] = [];
    configs: ConfigInterface[] = [];
    page_content: ContentModel[] = [];
    faq_group: FAQGroupModel[] = [];
    faq_item: FAQItemsModel[] = [];

    product!: ProductStateModel
    auth!: AuthStateModel
    partner!: PartnerStateModel
    location!: LocationStateModel
    transaction!: TransactionStateModel
}

export interface AboutInterface {
    id: number;
    title: string;
    content: string;
}

export class AboutModel extends BaseModel implements AboutInterface {
    id: number = 0;
    title: string = "";
    content: string = "";
}

export interface FeatureInterface {
    id: number;
    image_path: string;
}

export class FeatureModel extends BaseModel implements FeatureInterface {
    id: number = 0;
    image_path: string = "";
}

export interface TestimonialInterface {
    created_dateFormated: string;
    testimonial_id: string;
    trx_id: string;
    provider_id: string | null,
    customer_name: string;
    testimonial_description: string;
    rating: string;
    customer_email: string;
    CreatedDate: string;
    is_product: string;
    is_active: string;
    is_reviewed: string;
    is_send_reviewed: string;
    last_send_request_reviewed: string;
    total_send_request_reviewed: string;
    sticky: string;
    status: string;
    is_deleted: string;
    created_by: string | null,
    created_date: string;
    updated_by: string | null,
    updated_date: string | null;
}

export class TestimonialModel extends BaseModel implements TestimonialInterface {
    created_dateFormated: string = "";
    testimonial_id: string = "";
    trx_id: string = "";
    provider_id: string | null = null;
    customer_name: string = "";
    testimonial_description: string = "";
    rating: string = "";
    customer_email: string = "";
    CreatedDate: string = "";
    is_product: string = "";
    is_active: string = "";
    is_reviewed: string = "";
    is_send_reviewed: string = "";
    last_send_request_reviewed: string = "";
    total_send_request_reviewed: string = "";
    sticky: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string | null = null;
}

export interface EventAndPromoInterface {
    post_id: number;
    title_ind: string;
    title_eng: string;
    content_ind: string | null;
    content_eng: string | null;
    featured_image: string;
    flag: EVENT_PROMO_FLAG | null;
    tags: string | null;
    source_link: string | null;
    source_name: string | null;
    status: number | null;
    is_deleted: number;
    created_by: number | null;
    created_date: string | string;
    updated_by: number | null;
    updated_date: string;
}

export class EventAndPromoModel extends BaseModel implements EventAndPromoInterface {
    post_id: number = 0;
    title_ind: string = "";
    title_eng: string = "";
    content_ind: string | null = null;
    content_eng: string | null = null;
    featured_image: string = "";
    flag: EVENT_PROMO_FLAG | null = null;
    tags: string | null = null;
    source_link: string | null = null;
    source_name: string | null = null;
    status: number | null = null;
    is_deleted: number = 0;
    created_by: number | null = null;
    created_date: string | string = "";
    updated_by: number | null = null;
    updated_date: string = "";
}

export interface ConfigInterface {
    sys_config_id: string;
    description: string;
    value: string;
    value2: string;
    status: string;
    is_deleted: string;
    created_by: string | null;
    created_date: string | null;
    updated_by: string | null;
    updated_date: string | null;
}

export class ConfigModel extends BaseModel implements ConfigInterface {
    sys_config_id: string = "";
    description: string = "";
    value: string = "";
    value2: string = "";
    status: string = "";
    is_deleted: string = "";
    created_by: string | null = null;
    created_date: string | null = null;
    updated_by: string | null = null;
    updated_date: string | null = null;
}


export interface BSelectOption {
    text: string;
    value: any;
}

export interface ContentInterface {
    description: string | null;
    id: number;
    image: string;
    name: string | null;
    title: string | null;
    type: PAGE_CONTENT_TYPE;
}

export class ContentModel extends BaseModel implements ContentInterface {
    description: string | null = null;
    id: number = 0;
    image: string = "";
    name: string | null = null;
    title: string | null = null;
    type: PAGE_CONTENT_TYPE | null = null;
}


export interface FAQGroupInterface {
    group_id: number;
    title_eng: string;
    title_ind: string;
    sequence: number;
    status: number;
    is_delete: string | null;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export interface FAQItemsInterface {
    faq_id: number;
    group_id: number;
    title_eng: string;
    title_ind: string;
    content_eng: string;
    content_ind: string;
    flag: number;
    sequence: string | null;
    highlight: string | null;
    icon_highlight: string | null;
    title_highlight: string | null;
    status: number;
    is_delete: string | null;
    created_by: string | null;
    created_date: string;
    updated_by: string | null;
    updated_date: string;
}

export class FAQGroupModel extends BaseModel implements FAQGroupInterface {
    group_id: number = 0;
    title_eng: string = "";
    title_ind: string = "";
    sequence: number = 0;
    status: number = 0;
    is_delete: string | null = null;
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
}

export class FAQItemsModel extends BaseModel implements FAQItemsInterface {
    faq_id: number = 0;
    group_id: number = 0;
    title_eng: string = "";
    title_ind: string = "";
    content_eng: string = "";
    content_ind: string = "";
    flag: number = 0;
    sequence: string | null = null;
    highlight: string | null = null;
    icon_highlight: string | null = null;
    title_highlight: string | null = null;
    status: number = 0;
    is_delete: string | null = null;
    created_by: string | null = null;
    created_date: string = "";
    updated_by: string | null = null;
    updated_date: string = "";
}
