import { BaseModel } from "@/constant/BaseModel";
import { INSURANCE_TYPE } from "@/constant/Enums";
import { AdditionalCoverInterface } from "../motor/types";
import { ConfirmationUserInterface, ConfirmationUserModel, ConfirmationVehicleInterface, ConfirmationVehicleModel, PlateModel, ProductAdditionalInterface, ProductBrandInterface, ProductDataInterface, ProductDataModel, ProductFeatureInterface, ProductInterface, ProductModel, ProductSummaryInterface, ProductSummaryModel, ProductTestimonyInterface, ProductTestimonyModel } from "../types";

export interface ProductMobilStateInterface {
    onLoad: boolean;
    plates: PlateModel[];
    title: string;
    content: string;
    banner: string
    formCompare: FormCompareMobilInterface,
    summary: ProductSummaryInterface

}

export class ProductMobilState extends BaseModel implements ProductMobilStateInterface {
    onLoad: boolean = false;
    plates: PlateModel[] = [];
    title: string = "";
    content: string = "";
    banner: string = "";
    product: ProductDataInterface = new ProductDataModel
    formCompare: FormCompareMobilInterface = new FormCompareMobilModel;
    summary: ProductSummaryInterface = new ProductSummaryModel;
    listCompare: CompareContentInterface[] = []
    testimony: ProductTestimonyInterface = new ProductTestimonyModel;
    brands: ProductBrandInterface[] = [];
    confirmation: PaymentProductMobilConfirmationInterface = new PaymentProductMobilConfirmationModel;
    additional_cover: AdditionalCoverInterface[] = [];
}

export interface FormCompareMobilInterface {
    suminsured: number
    plate: string
    year: string
    coverage: string
}


export default class FormCompareMobilModel extends BaseModel implements FormCompareMobilInterface {
    type_insurance: number = INSURANCE_TYPE.CAR_INSURANCE
    suminsured: number = 0;
    plate: string = "";
    year: string = "";
    coverage: string = "";

    sort?: string = "a-z";
    /** max 4 */
    limit_person?: number

    /** plates -> area_id */
    area_id?: number

    /** plates -> state_id */
    city?: number
    car_year?: number
    /** from additional_id  */
    additional?: number[]
}

export interface CompareContentInterface {
    product: ProductInterface,
    feature?: ProductFeatureInterface[]
    additional?: ProductAdditionalInterface[]
}

export class CompareContentModel extends BaseModel implements CompareContentInterface {
    product: ProductModel = new ProductModel;
    // feature: ProductFeatureInterface[] = [];
}

export interface PaymentProductMobilConfirmationInterface {
    product: ProductInterface
    promo_code: string
    user: ConfirmationUserInterface
    vehicle: ConfirmationVehicleInterface
}

export class PaymentProductMobilConfirmationModel extends BaseModel implements PaymentProductMobilConfirmationInterface {
    product: ProductModel = new ProductModel;
    promo_code: string = "";
    user: ConfirmationUserModel = new ConfirmationUserModel;
    vehicle: ConfirmationVehicleModel = new ConfirmationVehicleModel;
}
