import { FormComparePropertyInterface } from "@/store/product/properti/types";
import BaseService, { ServiceBaseOptions } from ".";
import { ServerResponse } from "./response.types";

class ProductKecelakaanDiriService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}/${option?.api??'pa'}`;
    }

    /**
     * GetProvider
    */
    public GetKecelakaanDiri(): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService({api: `asuransi-kecelakaan-diri`})
        return self.Base().get('/');
    }

    /**
     * GetData
     */
    public GetData(form: FormData): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService
        return self.Base().post('get_data', form);
    }

    /**
     * Summary
    */
     public Summary(quotation_id: string, params?: any): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base().get(`summary/${quotation_id}`, { params });
    }

    /**
     * Compare
     */
    public Compare(quotation_ids: string[]) {
        let self = new ProductKecelakaanDiriService;
        let params = new FormData;
        quotation_ids.forEach((item, i)=>params.append(`param${i+1}`, item))
        return this.Base().post(`compare`, params)
    }

    /**
     * GetUlasan
     */
     public GetUlasan(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base({ api: process.env.VUE_APP_API_URL, hideError: true }).get(`home/testimony/${quotation_id ?? 'empty'}`);
     }

    /**
     * AddDetailProduct
    */
    public AddDetailProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base().post(`add_detail_produk/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public AddPersonalProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base().post(`add_personal_detail/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmProduct(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base().get(`confirm/${quotation_id}`);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmCheckout(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base().get(`checkout/${quotation_id}`);
    }

    /**
     * UseVoucher
    */
    public UseVoucher(quotation_id: string, params: FormData): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService({
            hideError: true,
            validate_status_code: false
        });
        return self.Base().post(`voucher/${quotation_id}`, params);
    }

    /**
     * UseVoucher
    */
    public GetBenefitCaries(params): Promise<ServerResponse> {
        let self = new ProductKecelakaanDiriService;
        return self.Base().get(`beneficiaries`, {params});
    }

}

export default new ProductKecelakaanDiriService;
