import { Module } from "vuex";
import { RootStateType } from "../types";
import { AuthStateModel } from "./types";

const state = new AuthStateModel
import actions from "./actions";
import mutations from "./mutations";

const auth: Module<AuthStateModel, RootStateType> = {
    namespaced: true,
    state,
    actions,
    mutations
}

export default auth
