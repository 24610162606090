import { UserModel } from "@/store/auth/types";
import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import { ServerResponse } from "./response.types";

class ProfileService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}/profile`;
    }

    /**
     * ChangePassword
    */
    public ChangePassword(params: any): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base().post('change-password', params);
    }
    /**
     * GetAddresBook
    */
    public GetAddresBook(params?: any): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base().get('address-book', { params });
    }
    /**
     * GetBackAccount
    */
    public GetBackAccount(params?: any): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base().get('bank-account', { params });
    }
    /**
     * AddAddresBook
    */
    public AddAddresBook(form: FormData): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base().post('add_address', form);
    }
    /**
     * AddAddresBook
    */
    public UpdateAddresBook(form: FormData): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base().post('update_address', form);
    }
    /**
     * AddAddresBook
    */
    public DeleteAddresBook(form: FormData): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base().post('delete_address', form);
    }

    /**
     * UpdateProfile
    */
    public UpdateProfile(params: UserModel|FormData): Promise<ServerResponse> {
        let self = new ProfileService;
        let form = new FormData
        if (params instanceof FormData) form = params
        else Object.keys(params).forEach((key) => form.append(key, params[key]))
        return self.Base().post('update', form);
    }

    /**
     * UpdateProfile
    */
    public GetProfile(): Promise<ServerResponse> {
        let self = new ProfileService;
        return self.Base({hideError: true}).get('');
    }

}

export default new ProfileService;
