import { Module } from "vuex";
import { RootStateModel } from "../types";
import { ProductStateModel } from "./types";

import actions from "./actions";
import mutations from "./mutations";
import motor from "./motor";
import mobil from "./mobil";
import properti from "./properti";
import kecelakaandiri from "./kecelakaandiri";
import kesehatan from "./kesehatan";

const product: Module<ProductStateModel, RootStateModel> = {
    namespaced: true,
    state: new ProductStateModel,
    actions,
    mutations,
    modules: {
        motor,
        mobil,
        properti,
        kecelakaandiri,
        kesehatan
    }
}


export default product
