import BaseService, { ServiceBaseOptions } from ".";
import { GetTokenParams } from "./params.types";
import { ServerResponse } from "./response.types";

class ProductMobilService extends BaseService {

    constructor(option?: ServiceBaseOptions) {
        super();
        this.api = `${this.api}/car`;
    }

    /**
     * GetProvider
     */
    public GetMobil(): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().get('index');
    }

    /**
     * GetData
     */
    public GetData(form: FormData): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().post('get_data', form);
    }

    /**
     * Summary
     */
    public Summary(quotation_id: string, params?: any): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().get(`summary/${quotation_id}`, { params });
    }

    /**
     * Compare
     */
    public Compare(quotation_ids: string[]) {
        let self = new ProductMobilService;
        let params = new FormData;
        quotation_ids.forEach((item, i)=>params.append(`param${i+1}`, item))
        return this.Base().post(`compare`, params)
    }

    /**
     * GetUlasan
     */
     public GetUlasan(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base({ api: process.env.VUE_APP_API_URL, hideError: true }).get(`home/testimony/${quotation_id ?? 'empty'}`);
     }

    /**
     * AddDetailProduct
    */
    public AddDetailProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().post(`add_detail_produk/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public AddPersonalProduct(form: FormData, quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().post(`add_personal_detail/${quotation_id}`, form);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmProduct(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().get(`confirm/${quotation_id}`);
    }

    /**
     * AddDetailProduct
    */
    public ConfirmCheckout(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().get(`checkout/${quotation_id}`);
    }

    /**
     * UseVoucher
    */
    public UseVoucher(quotation_id: string, params: FormData): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base({hideError: true}).post(`voucher/${quotation_id}`, params);
    }

    /**
     * GetBrands
    */
     public GetBrands(quotation_id: string): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().get(`get_brands/${quotation_id}`);
     }

    /**
     * GetAdditionalCover
     */
     public GetAdditionalCover(params?: any): Promise<ServerResponse> {
        let self = new ProductMobilService;
        return self.Base().get('get_additionals', { params });
     }

    /**
     * UpdateAdditionalCover
     */
    public UpdateAdditionalCover(params: {quotation_id: string, form: FormData}) {
        let self = new ProductMobilService;
        return self.Base().post(`update_additional/${params.quotation_id}`, params.form);
    }

}

export default new ProductMobilService;
