import { Module } from "vuex";
import { RootStateType } from "../types";
import { PartnerStateModel, PartnerStateInterface } from "./types";


const state = new PartnerStateModel
import actions from "./actions";
import mutations from "./mutations";

const product: Module<PartnerStateInterface, RootStateType> = {
    namespaced: true,
    state,
    actions,
    mutations
}


export default product
