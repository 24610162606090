import { MutationTree } from "vuex";
import { AddressBookInterface, AddressBookModel, AuthStateModel, UserModel, UserType } from "./types";

const mutations: MutationTree<AuthStateModel> = {
    "set-user": (state, payload: UserType) => {
        state.user = (new UserModel).SetRawAttributes(payload)
        state.user.full_name = `${state.user.first_name} ${state.user.last_name}`
    },
    "set-address-book": (state, addessList: AddressBookInterface[]) => {
        state.address = addessList.map((item)=>(new AddressBookModel).SetRawAttributes(item))
    }
}
export default mutations
